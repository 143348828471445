import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Merchandise from "../../../../../../images/logistic.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { useFormData } from "../../../../../../context/FormContext";
import { useLazyQuery } from "@apollo/client";
import { fetchPackages } from "../../../../queries/uploadData";
import localStorageService from "../../../../../../services/localStorageService";
const companyId = localStorageService.get("companyId");

const TabContentSix = () => {

  const [getPackages, dataPackages] = useLazyQuery(fetchPackages, {
    fetchPolicy: "no-cache",
  });


  const { formData, updateFormData } = useFormData();

  const handleDimensionsToggle = (event) => {
    const isChecked = event.target.checked;
    updateFormData({ enablePackageDimensions: isChecked });

    if (!isChecked) {
      updateFormData({
        packageType: "",
        weight: "",
        height: "",
        width: "",
        depth: "",
        cubicMeters: "",
        orderedQuantity: "",
        totalCubicMeters: "",
        packageContent: "",
      });
    }
  };

  useEffect(() => {
    if (formData.height && formData.width && formData.depth) {
      const cubicMeters = (formData.height * formData.width * formData.depth) / 1000000; // Convertir a metros cúbicos
      updateFormData({ cubicMeters });
    }
  }, [formData.height, formData.width, formData.depth]);

  useEffect(() => {
    if (formData.cubicMeters && formData.orderedQuantity) {
      const totalCubicMeters = formData.cubicMeters * formData.orderedQuantity;
      updateFormData({ totalCubicMeters });
    }
  }, [formData.cubicMeters, formData.orderedQuantity]);

  useEffect(() => {
    getPackages({
      variables: {
        companyId,
      },
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        className="option-content"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={Merchandise}
            alt="Merchandise"
            style={{
              maxWidth: "100%",
              maxHeight: "400px", // Puedes ajustar este valor según tus necesidades
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} className="option-content">
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            marginTop: "30px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            sx={{
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Mercancia
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxHeight: "400px",
              overflowY: "auto",
              marginTop: "20px"
            }}
          >
            <FormGroup sx={{ width: "70%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.enablePackageDimensions || false}
                    onChange={handleDimensionsToggle}
                  />
                }
                label="Habilitar dimensiones del paquete"
              />
            </FormGroup>

            {formData.enablePackageDimensions && (
              <>
                <FormControl
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                  variant="outlined"
                  required
                >
                  <InputLabel id="zona-label">Tipo de Paquete</InputLabel>
                  <Select
                    labelId="tipoPaquete-label"
                    id="tipoPaquete"
                    disabled={formData.isUpdate}
                    label="tipoPaquete"
                    name="packageId"
                    value={formData.packageId || ""}
                    onChange={(e) => updateFormData({ packageId: e.target.value })}
                    sx={{ height: "50px" }}
                  >
                    {dataPackages?.data?.packages?.length > 0 ? (
                      dataPackages.data.packages.map((pack) => (
                        <MenuItem key={pack.id} value={pack.id}>
                          {pack.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No paquetes disponibles</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <TextField
                  label="Peso (kg)"
                  type="number"
                  value={formData.weight || ''}
                  disabled={formData.isUpdate}
                  onChange={(e) => updateFormData({ weight: e.target.value })}
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="Alto (cm)"
                  type="number"
                  value={formData.height || ''}
                  disabled={formData.isUpdate}
                  onChange={(e) => updateFormData({ height: e.target.value })}
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="Ancho (cm)"
                  type="number"
                  value={formData.width || ''}
                  disabled={formData.isUpdate}
                  onChange={(e) => updateFormData({ width: e.target.value })}
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="Profundidad (cm)"
                  type="number"
                  value={formData.depth || ''}
                  disabled={formData.isUpdate}
                  onChange={(e) => updateFormData({ depth: e.target.value })}
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="Metros Cúbicos"
                  type="number"
                  value={formData.cubicMeters || ""}
                  fullWidth
                  margin="normal"
                  disabled
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="Cantidad"
                  type="number"
                  disabled={formData.isUpdate}
                  value={formData.orderedQuantity || ''}
                  onChange={(e) => updateFormData({ orderedQuantity: e.target.value })}
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="Metros Cúbicos Totales"
                  type="number"
                  value={formData.totalCubicMeters || ""}
                  fullWidth
                  margin="normal"
                  disabled
                  sx={{ width: "70%" }}
                />

                <TextField
                  label="Contenido del Paquete"
                  disabled={formData.isUpdate}
                  value={formData.packageContent || ""}
                  onChange={(e) => updateFormData({ packageContent: e.target.value })}
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                />
              </>
            )}

          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TabContentSix;