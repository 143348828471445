import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ClientData from "../../../../../../images/servicio-al-cliente.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { useFormData } from "../../../../../../context/FormContext";
import { useLazyQuery } from "@apollo/client";
import { fetchClients, fetchPaymentMethods, fetchSectors } from "../../../../queries/uploadData";
import localStorageService from "../../../../../../services/localStorageService";
const companyId = localStorageService.get("companyId");

const TabContentTwo = () => {

  const [getClient, dataClient] = useLazyQuery(fetchClients, {
    fetchPolicy: "no-cache",
  });
  const [getPaymentMethods, dataPaymentMethods] = useLazyQuery(
    fetchPaymentMethods,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });
  const { formData, updateFormData } = useFormData();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      updateFormData({
        [name]: checked,
      });
    } else {
      updateFormData({
        [name]: value,
      });
    }

    if (name === 'custIdentificationNumber') {
      const selectedClientNit = value.split('-')[0];
      const selectedClient = dataClient?.data?.clients?.find(client => client.nit === selectedClientNit);
      const sector = dataSectors?.data?.sectors?.find(
        (sector) => sector.id === selectedClient.clientAddresses?.[0]?.sectorId
      );
      if (selectedClient) {
        updateFormData({
          [name]: value,
          custFullName: selectedClient.name,
          custPhoneNumber: selectedClient.clientAddresses?.[0]?.phoneNumber || selectedClient.clientAddresses?.[0]?.cellPhoneNumber || '',
          custEmail: selectedClient.email || '',
          typeOfPerson: selectedClient.personType === 'organization' ? 'Persona Jurídica' : 'Persona Natural',
          state: selectedClient.clientAddresses?.[0]?.state,
          city: selectedClient.clientAddresses?.[0]?.city,
          address: selectedClient.clientAddresses?.[0]?.address,
          addressComplement: selectedClient.clientAddresses?.[0]?.addressComplement,
          destination: selectedClient.clientAddresses?.[0]?.lat + '-' + selectedClient.clientAddresses?.[0]?.lng,
          sectorId: sector ? sector.id : '',
        });
      } else {
        updateFormData({
          [name]: value,
          custFullName: '',
          custPhoneNumber: '',
          custEmail: '',
          typeOfPerson: 'Persona Natural',
        });
      }
    }
  };

  useEffect(() => {
    const loadInitData = async () => {
      getClient({
        variables: {
          companyId,
        },
      });
      getPaymentMethods({
        variables: {
          companyId,
        },
      });
      getSectors({
        variables: {
          companyId,
        },
      });
    };
    loadInitData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        className="option-content"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={ClientData}
            alt="ClientData"
            style={{
              maxWidth: "100%",
              maxHeight: "500px", // Puedes ajustar este valor según tus necesidades
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} className="option-content">
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          sx={{
            width: "100%",
            fontWeight: "bold",
          }}
        >
          Cliente
        </Typography>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            marginTop: "15px",
            maxHeight: "450px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "70%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isNewClient}
                      onChange={handleInputChange}
                      name="isNewClient"
                    />
                  }
                  label="Cliente nuevo"
                  sx={{
                    marginLeft: -1,
                    marginTop: "180px",
                    "& .MuiFormControlLabel-label": {
                      marginLeft: 1,
                    },
                  }}
                />
              </FormGroup>
            </Box>
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              <InputLabel id="identificacion-cliente-label">Identificación Cliente</InputLabel>
              <Select
                labelId="identificacion-cliente-label"
                id="identificacion-cliente"
                label="Identificación cliente"
                disabled={formData.isUpdate}
                value={formData.custIdentificationNumber || ""}
                onChange={handleInputChange}
                name="custIdentificationNumber"
                sx={{ height: "50px" }}
              >
                {dataClient?.data?.clients?.length > 0 ? (
                  dataClient.data.clients.map((client) => (
                    <MenuItem key={client.nit} value={client.nit}>
                      {`${client.nit}-${client.name}`}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No hay clientes disponibles</MenuItem>
                )}
              </Select>
            </FormControl>

            <TextField
              label="Nombre del cliente"
              variant="outlined"
              sx={{ width: "70%" }}
              margin="normal"
              required
              disabled={formData.isUpdate}
              value={formData.custFullName} // El valor viene del estado global
              onChange={handleInputChange} // Actualiza el valor cuando cambie el input
              name="custFullName" // Establece el nombre del campo para que la actualización se realice correctamente
            />

            <TextField
              label="Celular cliente"
              variant="outlined"
              sx={{ width: "70%" }}
              margin="normal"
              required
              value={formData.custPhoneNumber}
              onChange={handleInputChange}
              name="custPhoneNumber"
            />

            <TextField
              label="Email cliente"
              variant="outlined"
              sx={{ width: "70%" }}
              margin="normal"
              disabled={formData.isUpdate}
              required
              value={formData.custEmail}
              onChange={handleInputChange}
              name="custEmail"
            />

            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              <InputLabel id="tipo-de-persona-label">Tipo de persona</InputLabel>
              <Select
                labelId="tipo-de-persona-label"
                id="tipo-de-persona"
                label="Tipo de persona"
                value={formData.typeOfPerson}
                onChange={handleInputChange}
                name="typeOfPerson"
                sx={{ height: "50px" }}
              >
                <MenuItem value="Persona Juridica">Persona Juridica</MenuItem>
                <MenuItem value="Persona Natural">Persona Natural</MenuItem>
              </Select>
            </FormControl>
            {/* Requiere cobro checkbox */}
            <Box sx={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.paymentOrCashOnDeliveryRequired}
                      onChange={handleInputChange}
                      name="paymentOrCashOnDeliveryRequired"
                    />
                  }
                  label="Requiere cobro"
                  sx={{
                    marginLeft: -1,
                    "& .MuiFormControlLabel-label": {
                      marginLeft: 1,
                    },
                  }}
                />
              </FormGroup>
            </Box>

            {/* Campos adicionales solo si "Requiere cobro" está marcado */}
            {formData.paymentOrCashOnDeliveryRequired && (
              <>
                <FormControl
                  fullWidth
                  margin="normal"
                  sx={{ width: "70%" }}
                  variant="outlined"
                  required
                >
                  <InputLabel id="payment-method-label">Método de pago</InputLabel>
                  <Select
                    labelId="payment-method-label"
                    id="payment-method"
                    label="Método de pago"
                    disabled={formData.isUpdate}
                    value={formData.paymentMethod || ""}
                    onChange={handleInputChange}
                    name="paymentMethod"
                    sx={{ height: "50px" }}
                  >
                    {/* Aquí mapeamos los métodos de pago desde la respuesta de la API */}
                    {dataPaymentMethods?.data?.paymentMethods?.length > 0 ? (
                      dataPaymentMethods.data.paymentMethods.map((method) => (
                        <MenuItem key={method.id} value={method.id}>
                          {method.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No hay métodos de pago disponibles</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <TextField
                  label="Monto total"
                  variant="outlined"
                  sx={{ width: "70%" }}
                  margin="normal"
                  disabled={formData.isUpdate}
                  required
                  value={formData.totalOrderAmount || ''}
                  onChange={handleInputChange}
                  name="totalOrderAmount"
                />
              </>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TabContentTwo;