import React, { useState, useEffect, useRef } from "react";

import moment from "moment";
import imageSencillo from "../../../../images/turbo1.png";
import imageVehicle from "../../../../images/Vehiculo.png"
import { Grid, Divider, TextField, IconButton, InputAdornment, Box, Card, Pagination } from '@mui/material';
import Avatar from '@mui/material/Avatar';

const RouteCardLoad = ({ routesBatch, onSelectRoute }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = routesBatch.slice(indexOfFirstItem, indexOfLastItem);

  const [selectedRoute, setSelectedRoute] = useState(null);
  const selectedRef = useRef(null);
  let imageSelected = null;
  let imageName = null
  const handleRouteClick = (route) => {
    setSelectedRoute(route);
    onSelectRoute(route);
    imageName = route.routes[0]?.user?.userVehicle?.vehicle?.vehicleType?.name;

    if (imageName && imageName.length > 0) {
      if (imageName === "SENCILLO") {
        imageSelected = imageSencillo;
      } else if (imageName === "VEHICULO") {
        imageSelected = imageVehicle;
      }
    }

  };
  useEffect(() => {

    if (routesBatch?.length > 0 && !selectedRoute) {
      setSelectedRoute(routesBatch[0]);
      onSelectRoute(routesBatch[0]);
    }
  }, [routesBatch, selectedRoute, onSelectRoute]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="div-route-card">
      <Pagination
        count={Math.ceil(routesBatch.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        className="pagination-component-routesBatch"
      />

      {routesBatch?.length > 0 &&
        currentItems.map((route, index) => (
          <Box
            className="children-inner-container-target-with-bg-back"
            key={index}
            style={{
              marginBottom: "10px",
              padding: "20px",
              height: "100%",
              border: selectedRoute === route ? "2px solid var(--primaryColor)" : "2px solid transparent"
            }}
            onClick={() => handleRouteClick(route)}
          >
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Box className="drive-details-labels" >

                  <span className="driver-title">Conductor:</span> <br />
                  <span className="name-driver-title">{route.driverName}</span><br />
                  <span className="palte-value">{route.licencePlate}</span>

                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box className="drive-details-labels">

                  <img src={route.routes[0]?.user?.userVehicle?.vehicle?.vehicleType?.name == "SENCILLO" ? imageSencillo : imageVehicle} alt="Vehículo" style={{ width: "70%" }} /><br />
                  <span className="drive-details-value">{route.routes[0]?.user?.userVehicle?.vehicle?.vehicleType?.name}</span>

                </Box>

              </Grid>
            </Grid>
            <Divider variant="middle" style={{ marginBottom: "10px", marginTop: "5px" }} />

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box className="drive-details-labels">

                  <span className="created-date-title">Fecha de creación:</span> <br />
                  <span className="created-data-value">
                    {moment(route.createdAt).format("DD/MM/YYYY")}
                  </span><br />
                  <span className="hour-value">
                    {moment(route.createdAt).format("hh:mm:ss A")}
                  </span>
                </Box>
              </Grid>
            </Grid>

            <Divider variant="middle" style={{ marginBottom: "10px", marginTop: "5px" }} />

            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Box>

                  <span className="drive-details-value">Inicio de ruta</span>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box>

                  <span className="">{moment(route.startDateRoute).format("DD/MM/YYYY")}</span> <br />
                  <span className="hour-value">
                    {moment(route.startDateRoute).format("hh:mm:ss A")}
                  </span>
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box>

                  <span className="drive-details-value">Fin de ruta</span>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box>

                  <span className="">{moment(route.endDateRoute).format("DD/MM/YYYY")}</span> <br />
                  <span className="hour-value">
                    {moment(route.endDateRoute).format("hh:mm:ss A")}
                  </span>
                </Box>
              </Grid>
            </Grid>

            <Divider variant="middle" style={{ marginBottom: "10px", marginTop: "5px" }} />

            <Grid container spacing={1}>

              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar style={{ backgroundColor: "var(--primaryColor)" }} size="large">
                    {route.planner.charAt(0)}
                  </Avatar>
                  <div style={{ marginLeft: "10px" }}>
                    <span className="planner-title">Planificador:</span><br />
                    <span className="planner-value">{route.planner}</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box >
        ))}
    </div>
  );
};

export default RouteCardLoad;
