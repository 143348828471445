import React, { useEffect, useState } from 'react';
import TabContentOne from './TabInfoOrder';
import TabContentSix from './TabMerchandiseInformation';
import TabContentSeven from './TabLinesDetails';
import TabContentThree from './TabInfoDestination';
import TabContentTwo from './TabInfoClient';
import TabContentFour from './TabDeliveryCommitment';
import TabContentFive from './TabAditionalInformation';
import { useHistory } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import '../styles/HeaderUploadScreenV2.css';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import FormatAlignLeftOutlinedIcon from '@material-ui/icons/FormatAlignLeftOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import { useFormData } from '../../../../../../context/FormContext';
import Swal from 'sweetalert2';
import {
  fetchAddressBook,
  fetchOrders,
  insertOrdersTableNoLines,
  checkClientExists,
  updateTypePerson,
  updateOrdersTable,
  insertClient,
  deleteOrdersLine,
  insertOrdersLine,
  insertOrdersTable,
  insertRoute,
  OrdeNumberEdit
} from '../../../../queries/uploadData';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import loggerCooltrack from "../../../../../../services/logger-cooltrack";
import localStorageService from "../../../../../../services/localStorageService";
import axios from 'axios';
const companyId = localStorageService.get("companyId");
const userDistributionCenterId = localStorageService.get(
  "userDistributionCenterId"
);
const TabWithOptions = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const history = useHistory();
  const userid = localStorageService.get("id");
  const [ordersLine, setOrdersLine] = useState([]);
  const [formLinesDetail, setFormLinesDetail] = useState(false);
  const [formSinLinesDetail, setFormSinLinesDetail] = useState(false);
  const [dataRoute, setDataRoute] = useState({});
  const apolloClient = useApolloClient();

  const [instOrdersTableNoLines] = useMutation(insertOrdersTableNoLines, {
    fetchPolicy: "no-cache",
  });
  const [getAddressBook, dataAddressBook] = useLazyQuery(fetchAddressBook, {
    fetchPolicy: "no-cache",
  });
  const [getOrderRunning, dataOrderRunning] = useLazyQuery(fetchOrders, {
    fetchPolicy: "no-cache",
  });
  const [getCheckCliente, verficationExistClient] = useLazyQuery(
    checkClientExists,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [updateTypePersons] = useMutation(updateTypePerson, {
    fetchPolicy: "no-cache",
  });
  const [uptOrdersTable] = useMutation(updateOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [createClientNew] = useMutation(insertClient, {
    fetchPolicy: "no-cache",
  });
  const [dltOrdersTable] = useMutation(deleteOrdersLine, {
    fetchPolicy: "no-cache",
  });
  const [getOrdeNumberEdit] = useLazyQuery(OrdeNumberEdit, {
    fetchPolicy: "no-cache",
  });
  const [insOrdersTable] = useMutation(insertOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [addRoute] = useMutation(insertRoute, { fetchPolicy: "no-cache" });
  const options = [
    'INFORMACION DE LA ORDEN',
    'INFORMACION DEL CLIENTE',
    'INFORMACION DEL DESTINO',
    'COMPROMISO DE ENTREGA',
    'INFORMACION ADICIONAL',
    'INFORMACION DE LA MERCANCIA',
    'DETALLE DE LINEAS',
  ];
  const { formData, updateFormData } = useFormData(); // Accedemos al estado global

  const formType = formData.formType;
  const formEnableDeliveryWindow = formData.isDeliveryWindowEnabled;
  const formEnablePackageDimensions = formData.isDimensionsEnabled;
  const formPaymentOrCashOnDeliveryRequired = formData.requiresPayment;

  const isUpdate = formData.isUpdate;
  const getTabContent = () => {
    switch (options[selectedIndex]) {
      case 'INFORMACION DE LA ORDEN':
        return <TabContentOne />;
      case 'INFORMACION DEL CLIENTE':
        return <TabContentTwo />;
      case 'INFORMACION DEL DESTINO':
        return <TabContentThree />;
      case 'COMPROMISO DE ENTREGA':
        return <TabContentFour />;
      case 'INFORMACION ADICIONAL':
        return <TabContentFive />;
      case 'INFORMACION DE LA MERCANCIA':
        return <TabContentSix />;
      case 'DETALLE DE LINEAS':
        return <TabContentSeven />;
      default:
        return null;
    }
  };
  const orderTypes = [
    { label: "Envío", value: "delivery" },
    { label: "Recogida", value: "pickup" },
    { label: "Servicio Especial", value: "customService" },
    { label: "Traslado entre Bodegas", value: "warehousesTransfer" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value }); // Actualizamos el valor en el contexto
  };

  const handleNextTab = () => {
    if (selectedIndex < options.length - 1) {
      setSelectedIndex((prevIndex) => prevIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const secuenciaNumerica = async (force) => {
    let _sequenceOrderNumber = formData.orderNumber;
    if (force) {
      updateFormData.orderNumber = null;
    }
    if (!_sequenceOrderNumber) {
      try {
        const responseSequence = await axios({
          method: "POST",
          url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
          data: { companyId: companyId, sequenceTypeId: "orders" },
        });
        if (responseSequence.status === 200) {
          updateFormData.orderNumber = responseSequence.data.sequence;
          _sequenceOrderNumber = responseSequence.data.sequence;
          updateFormData({
            id: "",
            orderNumber: _sequenceOrderNumber,
            address: "",
            addressComplement: "",
            sectorId: "",
            sectorName: "",
            custIdentificationNumber: "",
            custFullName: "",
            custPhoneNumber: "",
            paymentMethod: null,
            totalOrderAmount: 0,
            destination: "",
            height: 0,
            width: 0,
            depth: 0,
            weight: 0,
            custEmail: "",
            priority: "",
            priorityName: "",
            routeRoutingId: "",
            routeRoutingName: "",
            orderRoutingId: "",
            orderRoutingName: "",
            city: "",
            state: "",
            notes: "",
            distributionCenterId: userDistributionCenterId,
            expectedDeliveryStartTime: null,
            expectedDeliveryEndTime: null,
            customerSupplierId: null,
            addressBookId: "",
            consecutiveBill: null,
            consecutiveRemission: null,
            consecutiveTRM: null,
            consecutiveSaleOrder: null,
            consecutiveShipping: null,
            consecutiveBurden: null,
            type: orderTypes[2]?.value !== null ? orderTypes[2]?.value : "",
            cubicMeters: 0,
            orderedQuantity: 0,
            packageId: "",
            packageContent: null,
            destinationDistributionCenterId: null,
            linesDetail: false,
            paymentOrCashOnDeliveryRequired: false,
            enableDeliveryWindow: false,
            totalCubicMeters: null,
            enablePackageDimensions: false,
            typePerson: "",
          });
          updateFormData.type('customService');
        }
      } catch (error) {
        console.error(error.toString());
      }
    }
    return _sequenceOrderNumber;
  };

  const handleSave = async () => {
    let _sequenceOrderNumber;
    const allLinesFilled = formData.detailLines.every((line) => {
      return Boolean(line.productName) && Boolean(line.productNumber);
    });
    if (
      !formData.orderNumber ||
      !formData.distributionCenterId ||
      !formData.type ||
      !formData.custFullName ||
      !formData.address ||
      !formData.destination ||
      !formData.sectorId ||
      !formData.priority ||
      !formData.custIdentificationNumber ||
      !formData.custPhoneNumber ||
      !formData.typeOfPerson
    ) {
      Swal.fire({
        title: "Campos vacios",
        text: `Hace falta diligenciar campos, por favor fijarse en los campos con asteriscos rojos`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (!formData.orderNumber && isUpdate === true) {
      Swal.fire({
        title: "Campos orden vacío",
        text: `Por favor elige una orden a editar más arriba`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      !formData.destinationDistributionCenterId &&
      formType === "warehousesTransfer"
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Hace falta diligenciar el campo Cliente/Proveedor/Centro`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "delivery" &&
      !formData.consecutiveSaleOrder &&
      !formData.consecutiveRemission &&
      !formData.consecutiveBill
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar numero de factura o remisión o pedido de venta`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "pickup" &&
      !formData.consecutiveBill &&
      !formData.consecutiveRemission
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de factura o remisión`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formType === "warehousesTransfer" &&
      !formData.consecutiveBurden &&
      !formData.consecutiveShipping &&
      !formData.consecutiveTRM
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de traslado o número del envío o número de la carga`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formPaymentOrCashOnDeliveryRequired === true &&
      !formData.totalOrderAmount
    ) {
      Swal.fire({
        title: "Campo de requiere cobro activo",
        text: "Se debe diligenciar el monto total",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      (formEnableDeliveryWindow === true &&
        !formData.expectedDeliveryStartTime &&
        !formData.expectedDeliveryEndTime) ||
      (formEnableDeliveryWindow === true &&
        !formData.expectedDeliveryStartTime) ||
      (formEnableDeliveryWindow === true &&
        !formData.expectedDeliveryEndTime)
    ) {
      Swal.fire({
        title: "Compromiso de entrega activo",
        text: "Por favor elige una fecha de inicio a fin de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (
      formEnablePackageDimensions === true &&
      !formData.packageId
    ) {
      Swal.fire({
        title: "Tipo de paquete vacío",
        text: "Por favor ingrese el tipo de paquete de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else if (formLinesDetail === true && allLinesFilled === false) {
      Swal.fire({
        title: "Detalle de línea vacío",
        text: "Por favor ingrese el código y nombre del producto",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setFormSinLinesDetail(false);
    } else {
      const {
        orderNumber,
        address,
        addressComplement,
        sectorId,
        custIdentificationNumber,
        custFullName,
        custPhoneNumber,
        paymentMethod,
        totalOrderAmount,
        destination,
        height,
        width,
        depth,
        weight,
        custEmail,
        priority,
        city,
        state,
        notes,
        distributionCenterId,
        expectedDeliveryStartTime,
        expectedDeliveryEndTime,
        customerSupplierId,
        addressBookId,
        consecutiveBill,
        consecutiveRemission,
        consecutiveTRM,
        consecutiveSaleOrder,
        consecutiveShipping,
        consecutiveBurden,
        type,
        cubicMeters,
        orderedQuantity,
        packageId,
        packageContent,
        destinationDistributionCenterId,
        linesDetail,
        paymentOrCashOnDeliveryRequired,
        enableDeliveryWindow,
        totalCubicMeters,
        enablePackageDimensions,
        id,
        typePerson,
        routeId,
        detailLines
      } = isUpdate ? formData : formData;

      try {
        const checkClient = await getCheckCliente({
          variables: { nit: custIdentificationNumber },
        });
        if (checkClient.data.clients.length == 0) {
          await createClientNew({
            variables: {
              personType: "organization",
              person: "customer",
              documentType: "NIT",
              nit: custIdentificationNumber,
              name: custFullName,
              accountNumber: custIdentificationNumber,
              currency: "Pesos colombianos ",
              email: custEmail,
              enabled: true,
              companyId: companyId,
              typePerson: formData.typeOfPerson,
            },
          });
        } else {
          {
            await updateTypePersons({
              variables: {
                nit: custIdentificationNumber,
                typePerson: formData.typeOfPerson,
                email: custEmail,
              },
            });
          }
        }
        let res;
        if (isUpdate) {
          dataAddressBook.called &&
            dataAddressBook.data !== undefined &&
            dataAddressBook.data.clientAddresses.length > 0
            ? (res = await uptOrdersTable({
              variables: {
                id,
                orderNumber,
                address,
                addressComplement,
                sectorId,
                custIdentificationNumber,
                custFullName,
                custPhoneNumber,
                paymentMethod,
                totalOrderAmount,
                destination,
                height,
                width,
                depth,
                weight,
                custEmail,
                priority,
                city,
                state,
                notes,
                distributionCenterId,
                expectedDeliveryStartTime,
                expectedDeliveryEndTime,
                customerSupplierId,
                addressBookId,
                consecutiveBill,
                consecutiveRemission,
                consecutiveTRM,
                consecutiveSaleOrder,
                consecutiveShipping,
                consecutiveBurden,
                type,
                cubicMeters,
                orderedQuantity,
                packageId,
                packageContent,
                destinationDistributionCenterId,
                linesDetail,
                paymentOrCashOnDeliveryRequired,
                enableDeliveryWindow,
                totalCubicMeters,
                enablePackageDimensions,
                typePerson,
                routeId
              },
            }))
            : (res = await uptOrdersTable({
              variables: {
                id,
                orderNumber,
                address,
                addressComplement,
                sectorId,
                custIdentificationNumber,
                custFullName,
                custPhoneNumber,
                paymentMethod,
                totalOrderAmount,
                destination,
                height,
                width,
                depth,
                weight,
                custEmail,
                priority,
                city,
                state,
                notes,
                distributionCenterId,
                expectedDeliveryStartTime,
                expectedDeliveryEndTime,
                customerSupplierId,
                consecutiveBill,
                consecutiveRemission,
                consecutiveTRM,
                consecutiveSaleOrder,
                consecutiveShipping,
                consecutiveBurden,
                type,
                cubicMeters,
                orderedQuantity,
                packageId,
                packageContent,
                destinationDistributionCenterId,
                linesDetail,
                paymentOrCashOnDeliveryRequired,
                enableDeliveryWindow,
                totalCubicMeters,
                enablePackageDimensions,
                typePerson,
                routeId
              },
            }));
          await apolloClient
            .mutate({
              mutation: deleteOrdersLine,
              variables: {
                deleteArray: formData.detailLines.map((item) => item.id),
              },
              fetchPolicy: "no-cache",
            })
            .then(() => {
              formData.detailLines.map((item) => {
                const { productNumber, productName, orderedQuantity } = item;
                return apolloClient
                  .mutate({
                    mutation: insertOrdersLine,
                    variables: {
                      orderNumber,
                      productNumber: item.productCode,
                      productName,
                      orderedQuantity: item.quantityOrdered,
                      initialQuantity: 0,
                    },
                    fetchPolicy: "no-cache",
                  })
                  .then(async () => {
                    await getOrderRunning({
                      variables: {
                        id,
                      },
                    });
                  });
              });
            });

          Swal.fire({
            title: "Orden Editada",
            text: "Orden Editada correctamente!",
            icon: "success",
            confirmButtonText: "Cerrar",
          });
          handleUpdate();
        } else {
          dataAddressBook.called &&
            dataAddressBook.data !== undefined &&
            dataAddressBook.data.clientAddresses.length > 0
            ? (res = await insOrdersTable({
              variables: {
                orderNumber,
                address,
                addressComplement,
                sectorId,
                custIdentificationNumber,
                custFullName,
                custPhoneNumber,
                paymentMethod,
                totalOrderAmount,
                destination,
                height,
                width,
                depth,
                weight,
                custEmail,
                priority,
                city,
                state,
                notes,
                distributionCenterId,
                companyId,
                expectedDeliveryStartTime,
                expectedDeliveryEndTime,
                customerSupplierId,
                addressBookId,
                consecutiveBill,
                consecutiveRemission,
                consecutiveTRM,
                consecutiveSaleOrder,
                consecutiveShipping,
                consecutiveBurden,
                type,
                cubicMeters,
                orderedQuantity,
                packageId,
                packageContent,
                destinationDistributionCenterId,
                linesDetail,
                paymentOrCashOnDeliveryRequired,
                enableDeliveryWindow,
                totalCubicMeters,
                enablePackageDimensions,
                routeId,
                createByUserId: userid,
                typePerson: formData.typeOfPerson,
                loadShipConfirmUTCDateTime: moment(moment().format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toISOString()
              },
            }))
            : (res = await insOrdersTable({
              variables: {
                orderNumber,
                address,
                addressComplement,
                sectorId,
                custIdentificationNumber,
                custFullName,
                custPhoneNumber,
                paymentMethod,
                totalOrderAmount,
                destination,
                height,
                width,
                depth,
                weight,
                custEmail,
                priority,
                city,
                state,
                notes,
                distributionCenterId,
                companyId,
                expectedDeliveryStartTime,
                expectedDeliveryEndTime,
                customerSupplierId,
                consecutiveBill,
                consecutiveRemission,
                consecutiveTRM,
                consecutiveSaleOrder,
                consecutiveShipping,
                consecutiveBurden,
                type,
                cubicMeters,
                orderedQuantity,
                packageId,
                packageContent,
                destinationDistributionCenterId,
                linesDetail,
                paymentOrCashOnDeliveryRequired,
                enableDeliveryWindow,
                totalCubicMeters,
                enablePackageDimensions,
                routeId,
                createByUserId: userid,
                typePerson: formData.typeOfPerson,
                loadShipConfirmUTCDateTime: moment(moment().format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toISOString()
              },
            }));

          if (res.data.insert_ordersTable.affected_rows > 0) {
            console.log(formData.detailLines);
            formData.detailLines.map((item) => {
              const { productNumber, productName, orderedQuantity } = item;
              return apolloClient
                .mutate({
                  mutation: insertOrdersLine,
                  variables: {
                    orderNumber,
                    productNumber: item.productCode,
                    productName,
                    orderedQuantity: item.quantityOrdered,
                    initialQuantity: 0,
                  },
                  fetchPolicy: "no-cache",
                })
                .then(async ({ data }) => {
                  if (data.insert_ordersLine.affected_rows > 0) {
                    loggerCooltrack({
                      module: "DataUpload",
                      operation: "ManualUploadData",
                      target: res.data.insert_ordersTable.returning[0].id,
                    });
                    setFormSinLinesDetail(false);
                  }
                });
            });
            const usr = JSON.parse(sessionStorage.getItem("userData"));
            if (dataRoute.userId) {
              addRoute({
                variables: {
                  userId: dataRoute.userId,
                  batch: dataRoute.assignedRoutes[0].routeBatch.batch,
                  routeOrder: dataRoute.assignedRoutes.length + 1,
                  orderTableId: res.data.insert_ordersTable.returning[0].id,
                  createByUserId: usr.id,
                  orderInVehicleTempId: null,
                },
                fetchPolicy: "no-cache",
              });
            }

            Swal.fire({
              title: "Orden ingresada",
              text: `Orden número ${formData.
                orderNumber
                } ingresada correctamente!`,
              icon: "success",
              confirmButtonText: "Cerrar",
            });
            loggerCooltrack({
              module: "DataUpload",
              operation: "ManualUploadData",
              target: res.data.insert_ordersTable.returning[0].id,
            });
            setFormSinLinesDetail(false);
            _sequenceOrderNumber = await secuenciaNumerica(true);
            setFormLinesDetail(false);
          }
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: "Error",
          text: `Hubo un error inesperado al ingresar los datos`,
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      }

      updateFormData({
        id: "",
        orderNumber: null,
        address: "",
        addressComplement: "",
        sectorId: "",
        sectorName: "",
        custIdentificationNumber: "",
        custFullName: "",
        custPhoneNumber: "",
        paymentMethod: null,
        totalOrderAmount: 0,
        destination: "",
        height: 0,
        width: 0,
        depth: 0,
        weight: 0,
        custEmail: "",
        priority: "",
        priorityName: "",
        routeRoutingId: "",
        routeRoutingName: "",
        orderRoutingId: "",
        orderRoutingName: "",
        city: "",
        state: "",
        notes: "",
        distributionCenterId: userDistributionCenterId,
        expectedDeliveryStartTime: null,
        expectedDeliveryEndTime: null,
        customerSupplierId: null,
        addressBookId:
          dataAddressBook.called &&
            dataAddressBook.data !== undefined &&
            dataAddressBook.data.clientAddresses.length > 0
            ? ""
            : null,
        consecutiveBill: null,
        consecutiveRemission: null,
        consecutiveTRM: null,
        consecutiveSaleOrder: null,
        consecutiveShipping: null,
        consecutiveBurden: null,
        type: "customService",
        cubicMeters: 0,
        orderedQuantity: 0,
        packageId:
          formEnablePackageDimensions === false || formLinesDetail === false
            ? null
            : "",
        packageContent: null,
        destinationDistributionCenterId: null,
        linesDetail: false,
        paymentOrCashOnDeliveryRequired: false,
        enableDeliveryWindow: false,
        totalCubicMeters: null,
        enablePackageDimensions: false,
        routeId: 0
      });
      setFormLinesDetail(false);
      updateFormData({
        detailLines: [],
      });
      secuenciaNumerica();
      handleGoToFirstTab();
    }
  };

  const handleSaveNoLines = async () => {
    let _sequenceOrderNumber;
    const allLinesFilled = ordersLine.every((line) => {
      return Boolean(line.productName) && Boolean(line.productNumber);
    });
    console.log("formData:", formData);
    if (
      !formData.orderNumber ||
      !formData.distributionCenterId ||
      !formData.typeOfPerson ||
      !formData.custFullName ||
      !formData.address ||
      !formData.destination ||
      !formData.sectorId ||
      !formData.priority ||
      !formData.custIdentificationNumber ||
      !formData.custPhoneNumber
    ) {
      Swal.fire({
        title: "Campos vacios",
        text: `Hace falta diligenciar campos, por favor fijarse en los campos con asteriscos rojos`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (!formData.orderNumber && isUpdate === true) {
      Swal.fire({
        title: "Campos orden vacío",
        text: `Por favor elige una orden a editar más arriba`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (
      !formData.destinationDistributionCenterId &&
      formType === "warehousesTransfer"
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Hace falta diligenciar el campo Cliente/Proveedor/Centro`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (
      formType === "delivery" &&
      !formData.consecutiveSaleOrder &&
      !formData.consecutiveRemission &&
      !formData.consecutiveBill
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar numero de factura o remisión o pedido de venta`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (
      formType === "pickup" &&
      !formData.consecutiveBill &&
      !formData.consecutiveRemission
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de factura o remisión`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (
      formType === "warehousesTransfer" &&
      !formData.consecutiveBurden &&
      !formData.consecutiveShipping &&
      !formData.consecutiveTRM
    ) {
      Swal.fire({
        title: "Campos Vacios",
        text: `Se debe diligenciar número de traslado o número del envío o número de la carga`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (
      formPaymentOrCashOnDeliveryRequired === true &&
      !formData.totalOrderAmount
    ) {
      Swal.fire({
        title: "Campo de requiere cobro activo",
        text: "Se debe diligenciar el monto total",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (
      (formEnableDeliveryWindow === true &&
        !formData.expectedDeliveryStartTime) &&
      !formData.expectedDeliveryEndTime ||
      (formEnableDeliveryWindow === true &&
        !formData.expectedDeliveryStartTime) ||
      (formEnableDeliveryWindow === true &&
        !formData.expectedDeliveryEndTime)
    ) {
      Swal.fire({
        title: "Compromiso de entrega activo",
        text: "Por favor elige una fecha de inicio a fin de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (
      formEnablePackageDimensions === true &&
      !formData.packageId
    ) {
      Swal.fire({
        title: "Tipo de paquete vacío",
        text: "Por favor ingrese el tipo de paquete de la orden",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (formLinesDetail === true && allLinesFilled === false) {
      Swal.fire({
        title: "Detalle de línea vacío",
        text: "Por favor ingrese el código y nombre del producto",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else if (!formData.typeOfPerson) {
      Swal.fire({
        title: "Tipo de persona",
        text: "Por favor ingrese el tipo de persona",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else {
      const {
        id,
        orderNumber,
        address,
        addressComplement,
        sectorId,
        custIdentificationNumber,
        custFullName,
        custPhoneNumber,
        paymentMethod,
        totalOrderAmount,
        destination,
        height,
        width,
        depth,
        weight,
        custEmail,
        priority,
        city,
        state,
        notes,
        distributionCenterId,
        expectedDeliveryStartTime,
        expectedDeliveryEndTime,
        customerSupplierId,
        addressBookId,
        consecutiveBill,
        consecutiveRemission,
        consecutiveTRM,
        consecutiveSaleOrder,
        consecutiveShipping,
        consecutiveBurden,
        type,
        cubicMeters,
        orderedQuantity,
        packageId,
        packageContent,
        destinationDistributionCenterId,
        linesDetail,
        paymentOrCashOnDeliveryRequired,
        enableDeliveryWindow,
        totalCubicMeters,
        enablePackageDimensions,
        routeId,
      } = isUpdate ? formData : formData;

      try {
        // Verificar si el cliente existe
        const checkClient = await getCheckCliente({
          variables: { nit: custIdentificationNumber },
        });

        if (checkClient.data.clients.length === 0) {
          // Si no existe, crear el cliente nuevo
          await createClientNew({
            variables: {
              personType: "organization",
              person: "customer",
              documentType: "NIT",
              nit: custIdentificationNumber,
              name: custFullName,
              accountNumber: custIdentificationNumber,
              currency: "Pesos colombianos",
              email: custEmail,
              enabled: true,
              companyId: companyId,
              typePerson: formData.typeOfPerson,
            },
          });
        } else {
          // Si ya existe, actualizar el tipo de persona
          await updateTypePersons({
            variables: {
              nit: custIdentificationNumber,
              typePerson: formData.typeOfPerson,
              email: custEmail,
            },
          });
        }

        // Lógica de inserción o actualización de la orden
        const commonVariables = {
          orderNumber,
          address,
          addressComplement,
          sectorId,
          custIdentificationNumber,
          custFullName,
          custPhoneNumber,
          paymentMethod,
          totalOrderAmount,
          destination,
          height,
          width,
          depth,
          weight,
          custEmail,
          priority,
          city,
          state,
          notes,
          distributionCenterId,
          companyId,
          expectedDeliveryStartTime,
          expectedDeliveryEndTime,
          customerSupplierId,
          addressBookId,
          consecutiveBill,
          consecutiveRemission,
          consecutiveTRM,
          consecutiveSaleOrder,
          consecutiveShipping,
          consecutiveBurden,
          type,
          cubicMeters,
          orderedQuantity,
          packageId,
          packageContent,
          destinationDistributionCenterId,
          linesDetail,
          paymentOrCashOnDeliveryRequired,
          enableDeliveryWindow,
          totalCubicMeters,
          enablePackageDimensions,
          createByUserId: userid,
          typePerson: formData.typeOfPerson,
          routeId,
          loadShipConfirmUTCDateTime: moment(moment().format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toISOString()
        };

        // Comprobamos si es una actualización
        if (isUpdate) {
          // Lógica para actualizar la orden
          if (dataAddressBook.called && dataAddressBook.data?.clientAddresses.length > 0) {
            await uptOrdersTable({
              variables: {
                ...commonVariables,
                id,
              },
            }).then(async ({ data }) => {
              if (data.update_ordersTable.affected_rows > 0) {
                Swal.fire({
                  title: "Orden Editada",
                  text: "Orden editada correctamente!",
                  icon: "success",
                  confirmButtonText: "Cerrar",
                });
              }
            });
          } else {
            await uptOrdersTable({
              variables: {
                ...commonVariables,
                id,
              },
            }).then(async ({ data }) => {
              if (data.update_ordersTable.affected_rows > 0) {
                Swal.fire({
                  title: "Orden Editada",
                  text: "Orden editada correctamente!",
                  icon: "success",
                  confirmButtonText: "Cerrar",
                });
              }
            });
          }
        } else {
          // Lógica para insertar una nueva orden
          if (dataAddressBook.called && dataAddressBook.data?.clientAddresses.length > 0) {
            await instOrdersTableNoLines({
              variables: commonVariables,
            }).then(async ({ data }) => {
              if (data.insert_ordersTable.affected_rows > 0) {
                Swal.fire({
                  title: "Orden Ingresada",
                  text: `Orden número ${formData.orderNumber} ingresada correctamente!`,
                  icon: "success",
                  confirmButtonText: "Cerrar",
                });
                handleGoToFirstTab();
                loggerCooltrack({
                  module: "DataUpload",
                  operation: "ManualUploadData",
                  target: data.insert_ordersTable.returning[0].id,
                });
                _sequenceOrderNumber = formData.orderNumber;
              }
            });
          } else {
            await instOrdersTableNoLines({
              variables: commonVariables,
            }).then(async ({ data }) => {
              if (data.insert_ordersTable.affected_rows > 0) {
                Swal.fire({
                  title: "Orden Ingresada",
                  text: `Orden número ${formData.orderNumber} ingresada correctamente!`,
                  icon: "success",
                  confirmButtonText: "Cerrar",
                });
                loggerCooltrack({
                  module: "DataUpload",
                  operation: "ManualUploadData",
                  target: data.insert_ordersTable.returning[0].id,
                });
                _sequenceOrderNumber = formData.orderNumber;
              }
            });
          }
        }

      } catch (error) {
        console.error(error);
      }

      // Resetear los datos del formulario
      updateFormData({
        id: "",
        orderNumber: null,
        address: "",
        addressComplement: "",
        sectorId: "",
        custIdentificationNumber: "",
        custFullName: "",
        custPhoneNumber: "",
        paymentMethod: null,
        totalOrderAmount: 0,
        destination: "",
        height: 0,
        width: 0,
        depth: 0,
        weight: 0,
        custEmail: "",
        priority: "",
        city: "",
        state: "",
        notes: "",
        distributionCenterId: 'a1afb10e-2f56-4705-8ea9-c121346baa30',
        expectedDeliveryStartTime: null,
        expectedDeliveryEndTime: null,
        customerSupplierId: null,
        addressBookId: null,
        consecutiveBill: null,
        consecutiveRemission: null,
        consecutiveTRM: null,
        consecutiveSaleOrder: null,
        consecutiveShipping: null,
        consecutiveBurden: null,
        type: 'customService',
        cubicMeters: 0,
        orderedQuantity: 0,
        packageId: null,
        packageContent: null,
        destinationDistributionCenterId: null,
        linesDetail: false,
        paymentOrCashOnDeliveryRequired: false,
        enableDeliveryWindow: false,
        totalCubicMeters: null,
        enablePackageDimensions: false,
        routeId: 0
      });

      updateFormData({
        linesDetail: false,
      });

      secuenciaNumerica();
      handleGoToFirstTab();
    }
  };

  const handleSubmit = async () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Quieres enviar los datos?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, enviar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if (formData.linesDetail) {
          handleSave()
        }
        else {
          handleSaveNoLines();
        }
      } else {
        Swal.fire(
          'Cancelado',
          'No se enviaron los datos.',
          'info'
        );
      }
    });
  };

  const handlePreviousTab = () => {
    setSelectedIndex((prevIndex) => {
      if (prevIndex === 0) {
        history.push('/main-upload');
        return prevIndex;
      }

      return (prevIndex - 1 + options.length) % options.length;
    });
  };

  const handleUpdate = () => {
    updateFormData({ isUpdate: !formData.isUpdate });
    if(formData.isUpdate){
      handleClean();
    }
  };  

  const handleClean = () => {
    updateFormData({
      id: "",
      orderNumber: null,
      address: "",
      addressComplement: "",
      sectorId: "",
      sectorName: "",
      custIdentificationNumber: "",
      custFullName: "",
      custPhoneNumber: "",
      paymentMethod: null,
      totalOrderAmount: 0,
      destination: "",
      height: 0,
      width: 0,
      depth: 0,
      weight: 0,
      custEmail: "",
      priority: "",
      priorityName: "",
      routeRoutingId: "",
      routeRoutingName: "",
      orderRoutingId: "",
      orderRoutingName: "",
      city: "",
      state: "",
      notes: "",
      distributionCenterId: userDistributionCenterId,
      expectedDeliveryStartTime: null,
      expectedDeliveryEndTime: null,
      customerSupplierId: null,
      addressBookId: "",
      consecutiveBill: null,
      consecutiveRemission: null,
      consecutiveTRM: null,
      consecutiveSaleOrder: null,
      consecutiveShipping: null,
      consecutiveBurden: null,
      type: orderTypes[2]?.value !== null ? orderTypes[2]?.value : "",
      cubicMeters: 0,
      orderedQuantity: 0,
      packageId: "",
      packageContent: null,
      destinationDistributionCenterId: null,
      linesDetail: false,
      paymentOrCashOnDeliveryRequired: false,
      enableDeliveryWindow: false,
      totalCubicMeters: null,
      enablePackageDimensions: false,
      typePerson: "",
      routeId: 0,
      detailLines: []
    });
    secuenciaNumerica();
  };

  const handleGoToFirstTab = () => {
    setSelectedIndex(0);
  };

  const getIconForOption = (index) => {
    switch (index) {
      case 0:
        return <AssignmentOutlinedIcon />;
      case 1:
        return <PersonPinOutlinedIcon />;
      case 2:
        return <ExploreOutlinedIcon />;
      case 3:
        return <LocalShippingOutlinedIcon />;
      case 4:
        return <PlaylistAddOutlinedIcon />;
      case 5:
        return <LocalMallOutlinedIcon />;
      case 6:
        return <FormatAlignLeftOutlinedIcon />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const loadInitData = async () => {
      getAddressBook({
        variables: {
          clientId: formData.custIdentificationNumber,
        },
      });
    };
    loadInitData();
  }, []);
  return (
    <div className="tab-container">
      <div className="header-container">
        <div className="button-container">
          <button onClick={handlePreviousTab} className="button-back">
            <ArrowBackOutlinedIcon />
            Atrás
          </button>
        </div>
        <div className="title-container">
          <h3 className="title-manual-upload">{options[selectedIndex]}</h3>
        </div>
        <button onClick={handleUpdate} className="button-back">
          <EditOutlinedIcon />
        </button>
        <button onClick={handleClean} className="button-back">
          <ClearOutlinedIcon />
        </button>
      </div>

      <div className="tab-content">
        {getTabContent()} {/* Aquí se renderiza el contenido basado en la opción seleccionada */}
      </div>

      <div className="button-container-2">
        <button onClick={handleNextTab} className="button-manual-upload">
          {selectedIndex === options.length - 1 ? "Enviar" : "Siguiente"}
        </button>
      </div>

      <div className="options-list">
        <ul className="option-items">
          <li
            key={0}
            className={`option-item ${selectedIndex === 0 ? 'selected' : ''}`}
            onClick={() => setSelectedIndex(0)}
          >
            <span className="icon">{getIconForOption(0)}</span>
            {options[0]}
          </li>
          <li
            key={1}
            className={`option-item ${selectedIndex === 1 ? 'selected' : ''}`}
            onClick={() => setSelectedIndex(1)}
          >
            <span className="icon">{getIconForOption(1)}</span>
            {options[1]}
          </li>
          <li
            key={2}
            className={`option-item ${selectedIndex === 2 ? 'selected' : ''}`}
            onClick={() => setSelectedIndex(2)}
          >
            <span className="icon">{getIconForOption(2)}</span>
            {options[2]}
          </li>
          <li
            key={3}
            className={`option-item ${selectedIndex === 3 ? 'selected' : ''}`}
            onClick={() => setSelectedIndex(3)}
          >
            <span className="icon">{getIconForOption(3)}</span>
            {options[3]}
          </li>
          <li
            key={4}
            className={`option-item ${selectedIndex === 4 ? 'selected' : ''}`}
            onClick={() => setSelectedIndex(4)}
          >
            <span className="icon">{getIconForOption(4)}</span>
            {options[4]}
          </li>
          <li
            key={5}
            className={`option-item ${selectedIndex === 5 ? 'selected' : ''}`}
            onClick={() => setSelectedIndex(5)}
          >
            <span className="icon">{getIconForOption(5)}</span>
            {options[5]}
          </li>
          <li
            key={6}
            className={`option-item ${selectedIndex === 6 ? 'selected' : ''}`}
            onClick={() => setSelectedIndex(6)}
          >
            <span className="icon">{getIconForOption(6)}</span>
            {options[6]}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TabWithOptions;
