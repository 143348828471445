import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import WorldWide from "../../../../../../images/world-wide.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { useFormData } from "../../../../../../context/FormContext";
import { fetchSectors } from "../../../../queries/uploadData";
import { useLazyQuery } from "@apollo/client";

const TabContentThree = () => {
  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });
  const { formData, updateFormData } = useFormData();
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    updateFormData({
      [name]: value,
    });
  };

  useEffect(() => {
    const loadInitData = async () => {
      getSectors();
    };
    loadInitData();
  }, []);
  return (
    <Grid container spacing={2} justifyContent="center" // Centra todo el contenido en el eje horizontal
      alignItems="center" >
      <Grid item xs={12} md={6} className="option-content-special">
        <Typography
          variant="h6"
          gutterBottom
          justifyContent="center" // Centra todo el contenido en el eje horizontal
          alignItems="center"
          sx={{
            width: "100%",
            fontWeight: "bold",
            textAlign: "center"
          }}
        >
          Destino
        </Typography>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            marginTop: "10px",
            maxHeight: "481px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              maxHeight: "450px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

            <TextField
              label="Departamento"
              variant="outlined"
              sx={{ width: "70%", marginTop: "160px" }}
              margin="normal"
              name="state"
              value={formData.state || ""}
              onChange={handleInputChange}
            />
            <TextField
              label="Ciudad"
              variant="outlined"
              sx={{ width: "70%" }}
              margin="normal"
              name="city"
              value={formData.city || ""}
              onChange={handleInputChange}
            />

            <TextField
              label="Dirección de entrega"
              variant="outlined"
              sx={{ width: "70%" }}
              margin="normal"
              required
              name="address"
              value={formData.address || ""}
              onChange={handleInputChange}
            />

            <TextField
              label="Complemento de dirección"
              variant="outlined"
              sx={{ width: "70%" }}
              margin="normal"
              required
              name="addressComplement"
              value={formData.addressComplement || ""}
              onChange={handleInputChange}
            />

            <TextField
              label="Latitud y longitud"
              variant="outlined"
              disabled
              value={formData.destination || "24321232,-123213"}
              sx={{ width: "70%" }}
              margin="normal"
              name="destination"
              onChange={handleInputChange}
            />
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              <InputLabel id="zona-label">Zona</InputLabel>
              <Select
                labelId="zona-label"
                id="zona"
                label="Zona"
                name="sectorId"
                value={formData.sectorId || ""}
                onChange={handleInputChange}
                sx={{ height: "50px" }}
              >
                {dataSectors?.data?.sectors?.length > 0 ? (
                  // Mapear las zonas devueltas por la API
                  dataSectors.data.sectors.map((sector) => (
                    <MenuItem key={sector.id} value={sector.id}>
                      {sector.name} {/* Mostramos el nombre de la zona */}
                    </MenuItem>
                  ))
                ) : (
                  // Si no hay zonas disponibles, mostramos un mensaje de error
                  <MenuItem value="">No hay zonas disponibles</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
            >
              <InputLabel id="prioridad-label">Prioridad</InputLabel>
              <Select
                labelId="prioridad-label"
                id="prioridad"
                label="Prioridad"
                name="priority"
                value={formData.priority || ""}
                onChange={handleInputChange}
              >
                <MenuItem value="a_high">Alto</MenuItem>
                <MenuItem value="b_medium">Medio</MenuItem>
                <MenuItem value="c_low">Bajo</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Notas"
              variant="outlined"
              sx={{ width: "70%" }}
              margin="normal"
              name="notes"
              value={formData.notes || ""}
              onChange={handleInputChange}
            />
          </Box>
        </Paper>
      </Grid>

      <Grid
        className="option-content"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={WorldWide}
            alt="WorldWide"
            style={{
              maxWidth: "100%",
              maxHeight: "500px",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TabContentThree;