import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect
} from "react-router-dom";

import { HomeScreen } from '../components/home/ui/screens/HomeScreen';
import { DashboardScreen } from '../components/dashboard/ui/screens/DashboardScreen';
import { LoginScreen } from '../components/login/ui/screens/LoginScreen';
import { MapSectorsSreen } from '../components/sectors/ui/screens/MapSectorsSreen';
import { SchedulingScreen } from '../components/scheduling/ui/screens/SchedulingScreen'
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { ReasonsScreen } from '../components/configuration/reasons/ui/screens/ReasonsScreen';
import { IncidentManagementScreen } from '../components/configuration/incident-management/ui/screens/IncidentManagementScreen';
import { IncidentsCausesScreen } from '../components/configuration/incidents-causes/ui/screens/IncidentsCausesScreen';
import { SequencesNumbersScreen } from '../components/configuration/sequences-numbers/ui/screens/SequencesNumbersScreen';
import { IncidentsScreen } from '../components/configuration/incidents/ui/screens/IncidentsScreen';
import { DistributionCentersScreen } from '../components/configuration/distribution-centers/ui/screens/DistributionCentersScreen';
import { VehicleTypesScreen } from '../components/configuration/vehicle-types/ui/screens/VehicleTypesScreen';
import { VehicleBrandsScreen } from '../components/configuration/vehicle-brands/ui/screens/VehicleBrandsScreen';
import { BrandingScreen } from '../components/configuration/branding/ui/screens/BrandingScreen';
import { TransportCompaniesScreen } from '../components/configuration/transportation-companies/ui/screens/TransportCompaniesScreen';
import { ClientsScreen } from '../components/configuration/clients/ui/screens/ClientsScreen';
import { PackagesScreen } from '../components/configuration/packages/ui/screens/PackagesScreen';
import { VehiclesScreen } from '../components/configuration/vehicles/ui/screens/VehiclesScreen';
import { IncidentReportScreen } from '../components/incidents/ui/screens/IncidentReportScreen';
import { ConfigurationSelectorScreen } from '../components/configuration-selector/ui/screens/ConfigurationSelectorScreen';
import { CompletedRoutesScreen } from '../components/completed-routes/ui/screens/CompletedRoutesScreen';
import { CompletedRoutesScreenV2 } from '../components/completed-routes-v2/ui/screens/CompletedRoutesScreenV2';
import { OnSiteDeliveriesScreen } from '../components/onSiteDeliveries/ui/screens/OnSiteDeliveriesScreen';
import { UploadDataScreen } from '../components/upload-data/ui/screens/UploadDataScreen';
import { MainUploadScreen } from '../components/upload-data/ui/screens/MainUploadScreen';
import { HeaderUploadScreen } from '../components/upload-data/ui/screens/manual-upload/HeaderUploadScreen';
import UsersScreen from '../components/configuration/users/ui/screens/UsersScreen';
import { VerificationEmailScreen } from '../components/login/ui/screens/VerificationEmailScreen';
import { ResetPassworScreen } from '../components/login/ui/screens/ResetPassworScreen';
import { ChangePasswordEmailScreen } from '../components/login/ui/screens/ChangePasswordEmailScreen';
import { ChangePasswordScreen } from '../components/login/ui/screens/ChangePasswordScreen';
import { TrackingOrderScreen } from '../components/scheduling/ui/screens/TrackingOrderScreen';
import { SchedulingProvider } from '../components/scheduling/context/SchedulingContext';
import { TMSLoadScreen } from '../components/upload-data/ui/screens/TMS-Dynamics/screen/TmsLoadScreen';
import { TMSLoadScreenV2 } from '../components/upload-data/ui/screens/TMS-DynamicsV2/screen/TmsLoadScreenV2';
import { TMSShipmentScreen } from '../components/upload-data/ui/screens/TMS-Dynamics/screen/TMSShipmentScreen';
import { ReleaseOrders } from "../components/configuration/releaseOrders/ui/screen/releaseOrders";
import { ConfigurationSelectorScreenV2 } from '../components/configuration-v2/ui/screens/ConfigurationSelectorScreenV2';
import TabContentOne from '../components/upload-data/ui/screens/manual-uploadV2/components/TabInfoOrder';
import TabWithOptions from '../components/upload-data/ui/screens/manual-uploadV2/components/TabWithOptions';

export const AppRoute = () => {
    return (
        <Router>
            <Switch>
                <PublicRoute exact path={'/login'} component={LoginScreen} />
                <PublicRoute exact path={'/verification-email/token/:token'} component={VerificationEmailScreen} />
                <PublicRoute exact path={'/reset-password'} component={ResetPassworScreen} />
                <PublicRoute exact path={'/restore-password/token/:token'} component={ChangePasswordEmailScreen} />
                <PublicRoute exact path={'/tracking-order/token/:token'} component={TrackingOrderScreen} />

                <PrivateRoute exact path={'/'} component={DashboardScreen} title="Dashboard" />
                <PrivateRoute exact path={'/home'} component={HomeScreen} title="Seguimiento Rutas" />
                <PrivateRoute exact path={'/main-upload'} component={MainUploadScreen} title="Cargue de Datos" />
                <PrivateRoute exact path={'/upload-file'} component={UploadDataScreen} title="Cargar Archivo" />
                <PrivateRoute exact path={'/manual-upload'} component={HeaderUploadScreen} title="Ingreso Manual de Orden" />
                <PrivateRoute exact path={'/manual-uploadV2'} component={TabWithOptions} title="Ingreso Manual de Orden" />
                <PrivateRoute exact path={'/TMSLoad'} component={TMSLoadScreen} title="TMS Carga" />
                <PrivateRoute exact path={'/TMSLoadV2'} component={TMSLoadScreenV2} title="TMS Carga" />
                <PrivateRoute exact path={'/TMSShipment'} component={TMSShipmentScreen} title="TMS Envios" />
                <PrivateRoute exact path={'/completed-routes'} component={CompletedRoutesScreen} title="Rutas Finalizadas" />
                <PrivateRoute exact path={'/completed-routesV2'} component={CompletedRoutesScreenV2} title="Rutas Finalizadas V2" />
                <PrivateRoute exact path={'/onSiteDeliveries'} component={OnSiteDeliveriesScreen} title="Entrega en Sitio" />
                <PrivateRoute exact path={'/sectors'} component={MapSectorsSreen} title="Zonas" />
                <PrivateRoute exact path={'/reasons'} component={ReasonsScreen} title="Motivos" />
                <PrivateRoute exact path={'/incidents'} component={IncidentReportScreen} title="Incidentes" />
                <PrivateRoute exact path={'/configuration'} component={ConfigurationSelectorScreen} title="Configuración" />
                <PrivateRoute exact path={'/configurationV2'} component={ConfigurationSelectorScreenV2} title="ConfiguraciónV2" />
                <PrivateRoute exact path={'/incidents-config'} component={IncidentsScreen} title="Maestro de Incidentes" />
                <PrivateRoute exact path={'/distribution-centers'} component={DistributionCentersScreen} title="Centros de distribución" />
                <PrivateRoute exact path={'/incidents-causes-config'} component={IncidentsCausesScreen} title="Causas de Incidentes" />
                <PrivateRoute exact path={'/sequences-numbers'} component={SequencesNumbersScreen} title="Secuencias numéricas" />
                <PrivateRoute exact path={'/incident-management-config'} component={IncidentManagementScreen} title="Gestión de Incidentes" />
                <PrivateRoute exact path={'/vehicle-types'} component={VehicleTypesScreen} title="Tipos de Vehículos"/>
                <PrivateRoute exact path={'/vehicle-brands'} component={VehicleBrandsScreen} title="Marcas de Vehículos"/>
                <PrivateRoute exact path={'/company-branding'} component={BrandingScreen} title="Personalización"/>
                <PrivateRoute exact path={'/transport-companies'} component={TransportCompaniesScreen} title="Empresas Transportistas"/>
                <PrivateRoute exact path={'/clients'} component={ClientsScreen} title="Clientes"/>
                <PrivateRoute exact path={'/packages'} component={PackagesScreen} title="Paquetes"/>
                <PrivateRoute exact path={'/vehicles'} component={VehiclesScreen} title="Vehículos"/>
                <PrivateRoute exact path={'/users'} component={UsersScreen} title="Usuarios"/>
                <PrivateRoute exact path={'/change-password/'} component={ChangePasswordScreen} title="Cambiar Contraseña"/>
                <PrivateRoute exact path={"/releaseOrders"} component={ReleaseOrders} title="Ordenes Liberadas" />
                <SchedulingProvider><PrivateRoute exact path={'/scheduling'} component={SchedulingScreen} title="Planificación" /></SchedulingProvider>
                <Redirect to={'/'} />
            </Switch>
        </Router>
    );
}
