import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Card,
} from "@mui/material";
import { useFormData } from "../../../../../../context/FormContext";
import AdInfo from "../../../../../../images/deposito.png";

const TabContentFive = () => {
  const { formData, updateFormData } = useFormData();
  const type = formData.type;
  const isUpdate = formData.isUpdate;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} className="option-content">
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            marginTop: "30px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            sx={{
              width: "100%",
              fontWeight: "bold",
            }}
          >
            Adicional
          </Typography>
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card title="Información adicional" size="small" sx={{
              boxShadow: "none", // Esto elimina la sombra del Card
              border: "none", // Elimina cualquier borde si es necesario
            }}>
              <Grid container spacing={2}>
                {(type === "delivery" || type === "pickup") && (
                  <Grid item xs={12}>
                    <TextField
                      label="Factura"
                      name="consecutiveBill"
                      value={formData.consecutiveBill || ""}
                      onChange={handleInputChange}
                      disabled={isUpdate}
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        width: "80%", // Puedes ajustar el tamaño aquí según lo necesites
                        display: "block",
                        margin: "0 auto", // Esto centrará los TextFields
                      }}
                    />
                  </Grid>
                )}

                {(type === "delivery" || type === "pickup") && (
                  <Grid item xs={12}>
                    <TextField
                      label="Remisión"
                      name="consecutiveRemission"
                      value={formData.consecutiveRemission || ""}
                      onChange={handleInputChange}
                      disabled={isUpdate}
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        width: "80%",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </Grid>
                )}

                {type === "warehousesTransfer" && (
                  <Grid item xs={12}>
                    <TextField
                      label="Número de Traslado"
                      name="consecutiveTRM"
                      value={formData.consecutiveTRM || ""}
                      onChange={handleInputChange}
                      disabled={isUpdate}
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        width: "80%",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </Grid>
                )}

                {type === "delivery" && (
                  <Grid item xs={12}>
                    <TextField
                      label="Pedido de Venta"
                      name="consecutiveSaleOrder"
                      value={formData.consecutiveSaleOrder || ""}
                      onChange={handleInputChange}
                      disabled={isUpdate}
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        width: "80%",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </Grid>
                )}

                {type === "warehousesTransfer" && (
                  <Grid item xs={12}>
                    <TextField
                      label="Número del Envío"
                      name="consecutiveShipping"
                      value={formData.consecutiveShipping || ""}
                      onChange={handleInputChange}
                      disabled={isUpdate}
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        width: "80%",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </Grid>
                )}

                {type === "warehousesTransfer" && (
                  <Grid item xs={12}>
                    <TextField
                      label="Número de la Carga"
                      name="consecutiveBurden"
                      value={formData.consecutiveBurden || ""}
                      onChange={handleInputChange}
                      disabled={isUpdate}
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        width: "80%",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Card>
          </Box>
        </Paper>
      </Grid>

      <Grid
        className="option-content"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={AdInfo}
            alt="AdInfo"
            style={{
              maxWidth: "100%",
              maxHeight: "500px",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TabContentFive;
