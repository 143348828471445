// FormContext.js

import React, { createContext, useState, useContext } from 'react';

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    address: '',
    addressComplement: '',
    city: '',
    consecutiveBill: null,
    consecutiveBurden: null,
    consecutiveRemission: null,
    consecutiveSaleOrder: null,
    consecutiveShipping: null,
    consecutiveTRM: null,
    state: '',
    detailLines: [],
    deliveryAddress: '',
    enableDeliveryWindow: false,
    enablePackageDimensions: false,
    expectedDeliveryEndTime: null,
    type: 'customService',
    height: 0,
    custIdentificationNumber: '',
    isDetailEnabled: false,
    isNewClient: false,
    destination: '',
    linesDetail: false,
    depth: 0,
    notes: '',
    orderNumber: '',
    packageContent: null,
    packageId: null,
    paymentMethod: null,
    paymentOrCashOnDeliveryRequired: false,
    priority: '',
    quantity: '',
    routeId: '',
    expectedDeliveryStartTime: null,
    totalOrderAmount: 0,
    totalCubicMeters: null,
    typeOfPerson: '',
    weight: 0,
    width: 0,
    sectorId: '',
    custFullName: '',
    distributionCenterId: 'a1afb10e-2f56-4705-8ea9-c121346baa30',
    custPhoneNumber: '',
    custEmail:'',
    cubicMeters:0,
    orderedQuantity:0,
    customerSupplierId: null
  });

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormData = () => useContext(FormContext);
