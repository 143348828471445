import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/lib/locale/es_ES';

import { UserProvider } from './context/UserContext';
import { FormProvider } from './context/FormContext';

import 'antd/dist/antd.css';
import './index.css';

import { AppRoute } from './routers/AppRoute';

ReactDOM.render(
  <UserProvider>
    <FormProvider>
      <ConfigProvider locale={es_ES}>
        <AppRoute />
      </ConfigProvider>
    </FormProvider>
  </UserProvider>,
  document.getElementById('root')
);
