import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeliveryData from "../../../../../../images/DeliveryData.png";
import { ArrowForwardIos, ConstructionOutlined } from "@mui/icons-material";
import localStorageService from "../../../../../../services/localStorageService";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  checkClientExists,
  deleteOrdersLine,
  fetchAddressBook,
  fetchClients,
  fetchCustomers,
  fetchFields,
  fetchOrders,
  fetchPackages,
  fetchPaymentMethods,
  fetchSectors,
  fetchUsers,
  fetchWarehouses,
  insertClient,
  insertOrdersTable,
  insertOrdersTableNoLines,
  insertRoute,
  OrdeNumberEdit,
  queryConfigureOptions,
  updateOrdersTable,
  updateTypePerson,
  updateuploadDataFieldsController,
} from "../../../../queries/uploadData";
import { fetchUsersVehicles } from "../../../../../home/queries/homeQueries";
import { useForm, Controller } from "react-hook-form";
import { isValidLatLng } from "../../../../../../helpers/utils";
import { useFormData } from "../../../../../../context/FormContext";
import axios from "axios";

const role = localStorageService.get("role");
const companyId = localStorageService.get("companyId");
const id = localStorageService.get("id");
const userid = localStorageService.get("id");
const userDistributionCenterId = localStorageService.get(
  "userDistributionCenterId"
);
const orderTypes = [
  { label: "Envío", value: "delivery" },
  { label: "Recogida", value: "pickup" },
  { label: "Servicio Especial", value: "customService" },
  { label: "Traslado entre Bodegas", value: "warehousesTransfer" },
];

const TabContentOne = () => {
  const [ordersLines, setOrdersLines] = useState([]);
  const [hasOrder, setHasOrder] = useState(false);
  const apolloClient = useApolloClient();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });
  const [getClient, dataClient] = useLazyQuery(fetchClients, {
    fetchPolicy: "no-cache",
  });
  const [getPaymentMethods, dataPaymentMethods] = useLazyQuery(
    fetchPaymentMethods,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [getUserId, dataUserId] = useLazyQuery(fetchUsers, {
    fetchPolicy: "no-cache",
  });
  const [getCustomers, dataCustomers] = useLazyQuery(fetchCustomers, {
    fetchPolicy: "no-cache",
  });
  const [getWarehouses, dataWarehouses] = useLazyQuery(fetchWarehouses, {
    fetchPolicy: "no-cache",
  });

  const [getAddressBook, dataAddressBook] = useLazyQuery(fetchAddressBook, {
    fetchPolicy: "no-cache",
  });
  const [getPackages, dataPackages] = useLazyQuery(fetchPackages, {
    fetchPolicy: "no-cache",
  });
  const [getRouteRunning, dataRouteRunning] = useLazyQuery(fetchUsersVehicles, {
    fetchPolicy: "no-cache",
  });
  const [getOrderRunning, dataOrderRunning] = useLazyQuery(fetchOrders, {
    fetchPolicy: "no-cache",
  });
  const [getFields, dataFields] = useLazyQuery(fetchFields, {
    fetchPolicy: "no-cache",
  });
  const [getCheckCliente, verficationExistClient] = useLazyQuery(
    checkClientExists,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [updateTypePersons] = useMutation(updateTypePerson, {
    fetchPolicy: "no-cache",
  });
  const [insOrdersTable] = useMutation(insertOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [instOrdersTableNoLines] = useMutation(insertOrdersTableNoLines, {
    fetchPolicy: "no-cache",
  });
  const [uptOrdersTable] = useMutation(updateOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [dltOrdersTable] = useMutation(deleteOrdersLine, {
    fetchPolicy: "no-cache",
  });
  const [createClientNew] = useMutation(insertClient, {
    fetchPolicy: "no-cache",
  });
  const [getOrdeNumberEdit] = useLazyQuery(OrdeNumberEdit, {
    fetchPolicy: "no-cache",
  });

  const [addRoute] = useMutation(insertRoute, { fetchPolicy: "no-cache" });
  const [updateUploadDataController] = useMutation(
    updateuploadDataFieldsController,
    { fetchPolicy: "no-cache" }
  );
  const [getConfigureOptions, dataConfigureOptions] = useLazyQuery(
    queryConfigureOptions,
    { fetchPolicy: "no-cache" }
  );
  const [dataConfig, setDataConfig] = useState([]);

  const validateField = (fieldName, objValue) => {
    if (
      dataFields.called &&
      dataFields.data &&
      dataFields.data.uploadDataTableController.length > 0
    ) {
      const field =
        dataFields.data.uploadDataTableController[0].uploadDataFields.filter(
          (item) => item.fieldName === fieldName
        );
      if (field.length > 0) {
        const def = field[0];
        const textToValidate = new RegExp(/^[a-zA-Z\s]*$/);
        const numberToValidate = new RegExp("^[0-9]+$");

        if (def.dataType === "OnlyText" && !textToValidate.test(objValue)) {
          return `Error: El valor '${objValue}' no es correcto, deben ser solo létras.`;
        } else if (
          def.dataType === "OnlyNumbers" &&
          !numberToValidate.test(objValue)
        ) {
          return `Error: El valor '${objValue}' no es un número válido.`;
        } else if (
          def.dataType === "OnlyNumbers" &&
          def.minValue !== null &&
          objValue.length < def.minValue
        ) {
          return `Error: El valor debe ser de al menos '${def.minValue}' caracteres (tiene ${objValue.length}).`;
        } else if (
          def.dataType === "OnlyNumbers" &&
          def.maxValue !== null &&
          objValue.length > def.maxValue
        ) {
          return `Error: El valor no puede ser mayor a '${def.maxValue}' caracteres (tiene ${objValue.length}).`;
        } else if (objValue === "" && def.mandatory) {
          return "Error: Obligatorio";
        } else if (objValue === "" && !def.mandatory) {
          return "";
        } else if (def.dataType === "Number" && isNaN(objValue)) {
          return `Error: El valor '${objValue}' no es un número válido.`;
        } else if (def.dataType === "LatLng" && !isValidLatLng(objValue)) {
          return `Error: El valor '${objValue}' no es una latitud/longitud válida.`;
        } else if (
          def.dataType === "Text" &&
          def.minValue !== null &&
          objValue.length < def.minValue
        ) {
          return `Error: El valor debe ser de al menos '${def.minValue}' caracteres (tiene ${objValue.length}).`;
        } else if (
          def.dataType === "Text" &&
          def.maxValue !== null &&
          objValue.length > def.maxValue
        ) {
          return `Error: El valor no puede ser mayor a '${def.maxValue}' caracteres (tiene ${objValue.length}).`;
        } else if (
          def.dataType === "Number" &&
          def.minValue !== 0 &&
          objValue < def.minValue
        ) {
          return `Error: El valor (${objValue}) no puede ser menor que '${def.minValue}'.`;
        } else if (
          def.dataType === "Number" &&
          def.maxValue !== null &&
          objValue > def.maxValue
        ) {
          return `Error: El valor (${objValue}) no puede ser mayor que '${def.maxValue}'.`;
        } else {
          return "";
        }
      } else {
        return `Error: Campo '${fieldName}' no existe.`;
      }
    }
    return "Error: Error no controlado.";
  };

  const isRequired = (fieldName) => {
    if (
      dataFields.called &&
      dataFields.data &&
      dataFields.data.uploadDataTableController.length > 0
    ) {
      const field =
        dataFields.data.uploadDataTableController[0].uploadDataFields.filter(
          (item) => item.fieldName === fieldName
        );

      if (field.length > 0) {
        return field[0].mandatory;
      } else {
        return false;
      }
    }

    return false;
  };

  const validateOrderNumber = (value) => {
    const required = isRequired("custIdentificationNumber", dataFields);
    if (required && !value) {
      return "Error: es obligatorio";
    }
    const validationMessage = validateField("orderNumber", value, dataFields);
    console.log(value);
    console.log(dataFields);
    if (validationMessage) {
      setHasOrder(false); // Puedes ajustar esta lógica según sea necesario
      return validationMessage;
    }
    setHasOrder(true);
    return true;
  };

  const { formData, updateFormData } = useFormData();

  const handleFormChange = (field, value) => {
    updateFormData({ [field]: value });
    if (field === 'orderNumber') {
      const selectedItem = dataOrdenNumEdit.find((item) => item.orderNumber === value);
      funCargaDataEdit(selectedItem.id);
    }
  };

  const secuenciaNumerica = async (force) => {
    let _sequenceOrderNumber = formData.orderNumber;

    if (!_sequenceOrderNumber && !formData.isUpdate) {
      try {
        const responseSequence = await axios({
          method: "POST",
          url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
          data: {
            companyId: companyId,
            sequenceTypeId: "orders",
          },
        });

        if (responseSequence.status === 200) {
          const newSequenceOrderNumber = responseSequence.data.sequence;

          updateFormData({
            orderNumber: newSequenceOrderNumber,
          }); 

        } else {
          console.error("Error al obtener la secuencia de número de orden");
        }
      } catch (error) {
        console.error("Error en la llamada a la API:", error);
      }
    }
  };
  const [dataOrdenNumEdit, setDataOrdenNumEdit] = useState([]);

  useEffect(() => {
    const loadInitData = async () => {
      await secuenciaNumerica(false);
      getUserId({
        variables: {
          id,
        },
      });
      getWarehouses({
        variables: {
          companyId,
        },
      });
      const responseOrdeNumberEdit = await getOrdeNumberEdit();
      setDataOrdenNumEdit(responseOrdeNumberEdit.data.ordersTable); getRouteRunning();
      getFields({
        variables: {
          tableName: "ordersTable",
        },
      });
    };
    loadInitData();
  }, []);

  const funCargaDataEdit = async (value) => {
    const cargaDataEdit = await getOrderRunning({
      variables: {
        id: value,
      },
    });

    cargarDataOrder(
      cargaDataEdit.data.ordersTable.filter((item) => item.id === value)[0]
    );
  };

  const cargarDataOrder = (dataOrderRunningCurrent) => {
    const transformedDetailLines = dataOrderRunningCurrent.ordersLines.map(orderLine => {
      return {
        id: orderLine.id,
        productCode: orderLine.productNumber,
        productName: orderLine.productName,
        quantityOrdered: orderLine.orderedQuantity
      };
    });
    const formatDateToLocalString = (isoDate) => {
      const date = new Date(isoDate); // Convierte el string ISO a un objeto Date
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Los meses son 0-indexados
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
  
    const formattedExpectedDeliveryStartTime = dataOrderRunningCurrent.expectedDeliveryStartTime
      ? formatDateToLocalString(dataOrderRunningCurrent.expectedDeliveryStartTime)
      : "";
      const formattedExpectedDeliveryEndTime = dataOrderRunningCurrent.expectedDeliveryEndTime
      ? formatDateToLocalString(dataOrderRunningCurrent.expectedDeliveryEndTime)
      : "";

    console.log(dataOrderRunningCurrent);
    updateFormData({
      id: dataOrderRunningCurrent.id,
      address: dataOrderRunningCurrent.address,
      addressComplement: dataOrderRunningCurrent.addressComplement,
      sectorId: dataOrderRunningCurrent.sectorId,
      sectorName: dataOrderRunningCurrent.sector.name,
      custIdentificationNumber:
        dataOrderRunningCurrent.custIdentificationNumber,
      custFullName: dataOrderRunningCurrent.custFullName,
      custPhoneNumber: dataOrderRunningCurrent.custPhoneNumber,
      paymentOrCashOnDeliveryRequired:
        dataOrderRunningCurrent.paymentMethod != null ? true : false,
      paymentMethod:
        dataOrderRunningCurrent.paymentMethod === null
          ? ""
          : dataOrderRunningCurrent.paymentMethod,
      totalOrderAmount:
        dataOrderRunningCurrent.totalOrderAmount === null
          ? 0
          : dataOrderRunningCurrent.totalOrderAmount,
      destination: dataOrderRunningCurrent.destination,
      height: dataOrderRunningCurrent.height,
      width: dataOrderRunningCurrent.width,
      depth: dataOrderRunningCurrent.depth,
      weight: dataOrderRunningCurrent.weight,
      custEmail: dataOrderRunningCurrent.custEmail,
      priority: dataOrderRunningCurrent.priority,
      priorityName: "",
      routeRoutingId: "",
      routeRoutingName: "",
      orderRoutingName: "",
      city: dataOrderRunningCurrent.city,
      state: dataOrderRunningCurrent.state,
      notes: dataOrderRunningCurrent.notes,
      distributionCenterId: dataOrderRunningCurrent.distributionCenterId,
      customerSupplierId: dataOrderRunningCurrent.customerSupplierId,
      consecutiveBill: dataOrderRunningCurrent.consecutiveBill,
      consecutiveRemission: dataOrderRunningCurrent.consecutiveRemission,
      consecutiveTRM: dataOrderRunningCurrent.consecutiveTRM,
      consecutiveSaleOrder: dataOrderRunningCurrent.consecutiveSaleOrder,
      consecutiveShipping: dataOrderRunningCurrent.consecutiveShipping,
      consecutiveBurden: dataOrderRunningCurrent.consecutiveBurden,
      type: dataOrderRunningCurrent.type,
      cubicMeters: dataOrderRunningCurrent.cubicMeters,
      orderedQuantity: dataOrderRunningCurrent.orderedQuantity,
      packageId: dataOrderRunningCurrent.packageId,
      packageContent: dataOrderRunningCurrent.packageContent,
      destinationDistributionCenterId:
        dataOrderRunningCurrent.destinationDistributionCenterId,
      enableDeliveryWindow: dataOrderRunningCurrent.enableDeliveryWindow,
      totalCubicMeters: dataOrderRunningCurrent.totalCubicMeters,
      enablePackageDimensions: dataOrderRunningCurrent.enablePackageDimensions === true ? updateFormData.enablePackageDimensions = true : updateFormData.enablePackageDimensions = false,
      linesDetail: dataOrderRunningCurrent.linesDetail === true ? updateFormData.linesDetail = true : updateFormData.linesDetail = false,
      detailLines: transformedDetailLines,
      expectedDeliveryStartTime: formattedExpectedDeliveryStartTime,
      expectedDeliveryEndTime: formattedExpectedDeliveryEndTime,
      typeOfPerson: dataOrderRunningCurrent.typePerson
    });
  };

  return (
    <Grid container spacing={2} >
      <Grid item xs={12} md={6} className="option-content">
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          sx={{
            width: "100%",
            fontWeight: "bold",
          }}
        >
          Orden
        </Typography>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "100%",
            marginTop: "10px",
            maxHeight: "481px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {formData.isUpdate ? (
              <FormControl sx={{ width: "70%" }}>
                <InputLabel>Seleccione...</InputLabel>
                <Select
                  value={formData.orderNumber}
                  onChange={(e) => handleFormChange("orderNumber", e.target.value)}
                  displayEmpty
                  label="Seleccione..."
                >
                  {dataOrdenNumEdit.map((item) => (
                    <MenuItem key={item.orderNumber} value={item.orderNumber}>
                      {item.orderNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                label="Número de orden"
                variant="outlined"
                value={formData.orderNumber}
                disabled
                sx={{ width: "70%" }}
              />
            )}
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%" }}
              variant="outlined"
              required
            >
              <InputLabel id="centro-distribucion-label">Centro de distribución</InputLabel>
              <Select
                labelId="centro-distribucion-label"
                id="centro-distribucion"
                label="Centro de distribución"
                value={formData.distributionCenterId || ""}
                disabled={formData.isUpdate}
                onChange={(e) => handleFormChange("distributionCenterId", e.target.value)}
                sx={{ height: "50px" }}
              >
                {dataWarehouses?.data?.distributionCenters?.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    {warehouse.name} ({warehouse.state})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%", height: "50px" }}
              variant="outlined"
            >
              <InputLabel id="tipo-label">Tipo</InputLabel>
              <Select
                labelId="tipo-label"
                id="tipo"
                disabled={formData.isUpdate}
                label="Tipo"
                value={formData.type || ""}
                onChange={(e) => handleFormChange("type", e.target.value)}
              >
                {orderTypes.map((order) => (
                  <MenuItem key={order.value} value={order.value}>
                    {order.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              sx={{ width: "70%", height: "100%" }}
              variant="outlined"
            >
              <TextField
                label="Ruta en ejecucion"
                type="number"
                value={formData.routeId || 0}
                onChange={(e) => handleFormChange("routeId", e.target.value)}
                fullWidth
                disabled={formData.isUpdate}
                margin="normal"
                sx={{ width: "100%" }}
              />
            </FormControl>
          </Box>
        </Paper>
      </Grid>

      <Grid
        className="option-content"
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
          <img
            src={DeliveryData}
            alt="Delivery Data"
            style={{
              maxWidth: "100%",
              maxHeight: "500px", // Puedes ajustar este valor según tus necesidades
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TabContentOne;