import React, { useEffect, useState } from 'react';
import '../styles/ConfigurationSelectorScreenV2.css';
import Grid from '@material-ui/core/Grid';
import UsersConfiguration from './UsersConfiguration';
import { useLazyQuery } from '@apollo/client';
import { getUsers } from '../queries/usersApi';
import PeopleIcon from '@material-ui/icons/People';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import RoomIcon from '@material-ui/icons/Room';
import CausesConfiguration from './CausesConfiguration';
import IncidentsConfiguration from './IncidentsConfiguration';
import ZonesConfiguration from './ZonesConfiguration';
import ManagmentConfiguration from './ManagmentConfiguration';
import ReasonConfiguration from './ReasonConfiguration';
import VehicleBrandConfiguration from './VehicleBrandConfiguration';
import ShipperConfiguration from './ShipperConfiguration';
import VehicleConfiguration from './VehicleConfiguration';
import DesignConfiguration from './DesignConfiguration';
import SystemParametersConfiguration from './SystemParametersConfiguration';
import DistributionCentersConfiguration from './DistributionCentersConfiguration';
import ClientsConfiguration from './ClientsConfiguration';
import ReleasedOrdersConfiguration from './ReleasedOrdersConfiguration';
import PackagesConfiguration from './PackagesConfiguration';
import NumberSequences from './NumberSequences';
import SettingsIcon from '@material-ui/icons/Settings';
import { AlertOutlined, AntDesignOutlined, BgColorsOutlined, CarOutlined, CheckOutlined, EnvironmentOutlined, ExclamationCircleOutlined, IdcardOutlined, InboxOutlined, MailOutlined, OrderedListOutlined, SettingOutlined, TruckOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';

export const ConfigurationSelectorScreenV2 = () => {
    const [selectedOption, setSelectedOption] = useState('usuarios');


    const handleSelection = (option) => {
        setSelectedOption(option);
    };

    return (
        <div className='children-inner-container-with-bg-1'>
            <Grid container spacing={1}>
                <Grid item xs={2} className='configuration-menu'>
                    <Grid container direction="column" justifyContent="space-between" className="configuration-submenu-container">
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'zonas' ? 'selected' : ''}`}
                            onClick={() => handleSelection('zonas')}
                        >
                            <EnvironmentOutlined className='icon-configuration' />
                            <span className="drive-details-label-configuration">Zonas</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'incidentes' ? 'selected' : ''}`}
                            onClick={() => handleSelection('incidentes')}
                        >
                            <ExclamationCircleOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Incidentes</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'causas' ? 'selected' : ''}`}
                            onClick={() => handleSelection('causas')}
                        >
                            <WarningOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Causas</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'gestiones' ? 'selected' : ''}`}
                            onClick={() => handleSelection('gestiones')}
                        >
                            <SettingOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Gestiones</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'motivos' ? 'selected' : ''}`}
                            onClick={() => handleSelection('motivos')}
                        >
                            <AlertOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Motivos</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'usuarios' ? 'selected' : ''}`}
                            onClick={() => handleSelection('usuarios')}
                        >
                            <UserOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Usuarios</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'marcasVehiculos' ? 'selected' : ''}`}
                            onClick={() => handleSelection('marcasVehiculos')}
                        >
                            <AntDesignOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Marcas de Vehiculos</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'transportistas' ? 'selected' : ''}`}
                            onClick={() => handleSelection('transportistas')}
                        >
                            <LocalShippingOutlinedIcon className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Transportistas</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'vehiculos' ? 'selected' : ''}`}
                            onClick={() => handleSelection('vehiculos')}
                        >
                            <CarOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Vehículos</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'centrosDistribucion' ? 'selected' : ''}`}
                            onClick={() => handleSelection('centrosDistribucion')}
                        >
                            <InboxOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Centros de Distribución</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'clientes' ? 'selected' : ''}`}
                            onClick={() => handleSelection('clientes')}
                        >
                            <IdcardOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Clientes</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'paquetes' ? 'selected' : ''}`}
                            onClick={() => handleSelection('paquetes')}
                        >
                            <MailOutlined className='icon-configuration' />
                            <span className="drive-details-label-configuration">Paquetes</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'secuenciasNumericas' ? 'selected' : ''}`}
                            onClick={() => handleSelection('secuenciasNumericas')}
                        >
                            <OrderedListOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Secuencias Numericas</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'diseño' ? 'selected' : ''}`}
                            onClick={() => handleSelection('diseño')}
                        >
                            <BgColorsOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Diseño</span><br />
                        </Grid>
                        <Grid
                            item
                            className={`configuration-submenu ${selectedOption === 'ordenesLiberadas' ? 'selected' : ''}`}
                            onClick={() => handleSelection('ordenesLiberadas')}
                        >
                            <CheckOutlined className='icon-configuration'/>
                            <span className="drive-details-label-configuration">Ordenes Liberadas</span><br />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10} className='subcontainer-configuration'>
                    {selectedOption === 'usuarios' ? (
                        <UsersConfiguration></UsersConfiguration>
                    ) : selectedOption === 'causas' ? (
                        <CausesConfiguration></CausesConfiguration>
                    ) : selectedOption === 'incidentes' ? (
                        <IncidentsConfiguration></IncidentsConfiguration>
                    ) : selectedOption === 'zonas' ? (
                        <ZonesConfiguration ></ZonesConfiguration>
                    ) : selectedOption === 'gestiones' ? (
                        <ManagmentConfiguration></ManagmentConfiguration>
                    ) : selectedOption === 'motivos' ? (
                        <ReasonConfiguration></ReasonConfiguration>
                    ) : selectedOption === 'marcasVehiculos' ? (
                        <VehicleBrandConfiguration></VehicleBrandConfiguration>
                    ) : selectedOption === 'transportistas' ? (
                        <ShipperConfiguration></ShipperConfiguration>
                    ) : selectedOption === 'vehiculos' ? (
                        <VehicleConfiguration></VehicleConfiguration>
                    ) : selectedOption === 'diseño' ? (
                        <DesignConfiguration></DesignConfiguration>
                    ) : selectedOption === 'parametros' ? (
                        <SystemParametersConfiguration></SystemParametersConfiguration>
                    ) : selectedOption === 'centrosDistribucion' ? (
                        <DistributionCentersConfiguration></DistributionCentersConfiguration>
                    ) : selectedOption === 'clientes' ? (
                        <ClientsConfiguration></ClientsConfiguration>
                    ) : selectedOption === 'ordenesLiberadas' ? (
                        <ReleasedOrdersConfiguration></ReleasedOrdersConfiguration>
                    ) : selectedOption === 'paquetes' ? (
                        <PackagesConfiguration></PackagesConfiguration>
                    ) : selectedOption === 'secuenciasNumericas' ? (
                        <NumberSequences></NumberSequences>
                    ) :
                        (
                            <p>No hay datos seleccionados.</p>
                        )}
                </Grid>
            </Grid>
        </div>
    );
}











