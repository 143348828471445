import React from "react";
import { Grid, Avatar } from '@mui/material';
import moment from "moment";
import imageStatus from "../../../../images/logistic.png"; // Verifica esta ruta

const OrdersCardDetails = ({ dailsOrder }) => {
    return (
        <div
            style={{
                position: 'relative', // Contenedor relativo para posicionar la imagen
                overflow: 'hidden',
                padding: '20px',
                minHeight: '150px', // Altura mínima consistente
            }}
            
        >
            {/* Imagen posicionada absolutamente */}
            <img
                src={imageStatus}
                alt="status"
                style={{
                    position: 'absolute',
                    top: '70px', // Posiciona la imagen desde arriba
                    right: '10px', // Posiciona la imagen desde la derecha
                    width: '60px', // Tamaño reducido
                    height: 'auto', // Mantener proporción
                    zIndex: 1, // Coloca la imagen encima del contenido
                }}
                className="selected-status"
            />

            <Grid container spacing={1} style={{ position: 'relative', zIndex: 0 }}> {/* Contenido principal */}
                <Grid item xs={4}>
                    <div>
                        <div>
                            <span className="drive-details-label">Cliente:</span><br />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Avatar style={{ backgroundColor: "var(--primaryColor)", marginRight: "15px" }} size="large">
                                {dailsOrder?.orderTable?.custFullName?.charAt(0)}
                            </Avatar>
                            <span className="drive-details-value planificator">{dailsOrder?.orderTable?.custFullName}</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                        <span className="drive-details-label">Dirección:</span><br />
                        <span className="drive-details-value planificator">{dailsOrder?.orderTable?.address}</span>
                    </div>
                </Grid>
                <Grid item xs={2.5}>
                    <div>
                        <span className="drive-details-label">Fecha inicio:</span><br />
                        <span className="drive-details-value">
                            {moment(dailsOrder?.orderTable?.expectedDeliveryStartTime).format("DD/MM/YYYY")}
                        </span>
                        <br />
                        <span className="drive-details-value">
                            {moment(dailsOrder?.orderTable?.expectedDeliveryStartTime).format("HH:mm:ss")}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={2.5}>
                    <div>
                        <span className="drive-details-label">Fecha Fin:</span><br />
                        <span className="drive-details-value">
                            {moment(dailsOrder?.orderTable?.expectedDeliveryEndTime).format("DD/MM/YYYY")}
                        </span>
                        <br />
                        <span className="drive-details-value">
                            {moment(dailsOrder?.orderTable?.expectedDeliveryEndTime).format("HH:mm:ss")}
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default OrdersCardDetails;
