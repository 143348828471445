import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { Space, Card, Button, Table, Tag, Alert, Row, Progress, message, Col } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, SortAscendingOutlined, HistoryOutlined, CaretUpOutlined, CaretDownOutlined, ArrowDownOutlined, WarningOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import axios from "axios";
import moment from 'moment';
import 'moment/locale/es';

import Icon from '@ant-design/icons';

import {
    updateSortPositionOrdersInVehicleTemp
} from '../../queries/schedulingQueries';

import { useScheduling } from '../../context/SchedulingContext';

const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const MuliselectIconSVG = () => (

    <svg
        width="1em"
        height="1em"
        fill="currentColor"
        viewBox="0 0 264 571">

        <path d="M16.45,129.41c9.91-8,19.82-16,32-25.87l38,41.57,128-128.34,28.14,30.55L86.33,203.51,16.45,136.57Z" />
        <path d="M16.45,297.14l32-25.87,38,41.57,128-128.34L242.56,215,86.32,371.23,16.45,304.3Z" />
        <path d="M16,464.71l32-25.87,38,41.58L214,352.07l28.09,30.5L85.87,538.81,16,471.87Z" />
    </svg>
);

const columns = [
    {
        title: <SortAscendingOutlined />,
        dataIndex: 'sort',
        width: 25,
        className: 'drag-visible',
        align: 'center',
        render: () => <small><DragHandle /></small>
    },
    {
        title: 'Zona',
        dataIndex: 'color',
        key: 'color',
        align: 'center',
        width: 70,
        render: (color) => <Tag color={color} style={{ borderRadius: '10px', height: '1em' }} />
    },
    {
        title: '# Orden',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        align: 'center',
        render: (text) => <small>{text}</small>
    },
    {
        title: 'Peso',
        dataIndex: 'orderWeight',
        key: 'orderWeight',
        align: 'center',
        render: (value) => <small>{value?.toLocaleString('es')} kgs</small>
    }
];

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);
const getWindowScroll = () => {
    const doc = document.documentElement;
    return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
}

export const OrdersInVehicleList = (props) => {
    const {
        fetching,
        setFetching,
        ordersInVehicleTemp,
        setOrdersInVehicleTemp,
        setToRightButtonDisabled,
        setSelectedLeftRow,
        onInsertRoute,
        toRightButtonDisabled,
        selectedUserVehicle,
        onClickToRightButton,
        onCalcRoute,
        //selectedLeftRow
        //optimalRoute
    } = useScheduling();

    let itemList = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dragStartTop, setDragStartTop] = useState(null);
    const [dragStartY, setDragStartY] = useState(null);

    const [selectAll, setSelectAll] = useState(false);

    const [selectOrdersInVehicleTemp, setSelectOrdersInVehicleTemp] = useState([]);

    const [ordersDeliveryEstimation, setOrdersDeliveryEstimation] = useState([]);
    const { selectedDate, setSelectedDate } = useScheduling();  // Obtener el valor y la función de actualización


    useEffect(async () => {

        if (selectOrdersInVehicleTemp !== ordersInVehicleTemp && ordersInVehicleTemp.length > 0 && selectedUserVehicle) {
            setSelectOrdersInVehicleTemp(ordersInVehicleTemp);

            let _waypoints = ordersInVehicleTemp.map(item => item.destination);

            _waypoints.pop();

            const res2 = await axios.post(`https://cooltrack-functions.azurewebsites.net/api/GetWaypoints`,
                {
                    "origin": selectedUserVehicle.lastLocation,
                    "destination": ordersInVehicleTemp[ordersInVehicleTemp.length - 1].destination,
                    "waypoints": _waypoints
                });
            if ((res2?.data?.routes[0]?.legs ?? []).length > 0)
                setOrdersDeliveryEstimation(ordersCaculated(ordersInVehicleTemp, res2.data.routes[0].legs.map(item => item.duration.value)));
        } else {
            setOrdersDeliveryEstimation([]);
        }
    }, [ordersInVehicleTemp]);

    const ordersCaculated = (tOrders, durations) => {

        let lastTimeValue = null;

        let mOrders = tOrders.map((order, index) => {


            let timeValue = null;
            let response;

            if (index === 0) {
                timeValue = moment().add(durations[index], 's').format("YYYY-MM-DD hh:mm A");
                response = { id: order.id, lastTimeValue: moment().format("YYYY-MM-DD hh:mm A"), timeValue };
            } else {
                timeValue = moment(lastTimeValue).add(durations[index], 's').format("YYYY-MM-DD hh:mm A");
                response = { id: order.id, lastTimeValue: moment(lastTimeValue).add(durations[index], 's').format("YYYY-MM-DD hh:mm A"), timeValue };
            }

            lastTimeValue = moment(timeValue, 'YYYY-MM-DD hh:mm A');

            return response;
        });

        return mOrders;
    };

    const [updateSortPosition] = useMutation(updateSortPositionOrdersInVehicleTemp, { fetchPolicy: 'no-cache' });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            setFetching(true);
            const newData = arrayMove([].concat(ordersInVehicleTemp), oldIndex, newIndex).filter(el => !!el);

            for (let i = 0; i < newData.length; i++) {
                const item = newData[i];
                delaySort(i, item);
            }

            setOrdersInVehicleTemp(newData);
            setFetching(false);
        }
    };

    const delaySort = (i, item) => {
        setTimeout(async () => {
            await updateSortPosition({
                variables: {
                    id: item.id,
                    orderPosition: new Date()
                }
            });
        }, i * 10);
    };

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = ordersInVehicleTemp.findIndex(x => x.key === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const initialiseDrag = event => {
        const { target, clientY } = event;
        const { offsetTop } = target;
        const { top } = itemList.current.getBoundingClientRect();
        setDragStartTop(top - offsetTop);
        setDragStartY(clientY);
        window.addEventListener('mousemove', startDragging, false);
        window.addEventListener('mouseup', stopDragging, false);
    };

    const startDragging = ({ clientY }) => {
        let newTop = dragStartTop + clientY - dragStartY + getWindowScroll();
        if (newTop < 0) newTop = 0;
        itemList.current.style.transform = `translateY(${newTop}px)`;
        scrollIfElementBottom(newTop);
    };

    const stopDragging = () => {
        window.removeEventListener('mousemove', startDragging, false);
        window.removeEventListener('mouseup', stopDragging, false);
    };

    const scrollIfElementBottom = newTop => {
        if (newTop > 30) {
            window.scroll({
                top: newTop,
                behavior: 'smooth'
            });
        }
        if (newTop < 30) {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const format_number = str => {
        str += '';
        let x = str.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    };
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const [distances, setDistances] = useState([]);
    const [hours, setHours] = useState([]);

    const calculateDistance = async (ordersInVehicleTemp) => {
        setIsLoading(true);
        if (ordersInVehicleTemp) {
            for (let index = 0; index < ordersInVehicleTemp.length; index++) {
                const element = ordersInVehicleTemp[index];
                let origin;
                if (index === 0) {
                    origin = "4.391145,-75.138936";
                } else {
                    origin = ordersInVehicleTemp[index - 1].destination;
                }

                try {
                    const response = await axios({
                        method: "POST",
                        url: process.env.REACT_APP_FUNCTION_GET_DISTANCE_SCHEDULING,
                        data: {
                            origin: origin,
                            destination: element.destination,
                            apiKey: apiKey,
                        },
                    });
                    let updatedTime;
                    if (response.status === 200) {
                        let time = response.data.data.rows[0].elements[0].duration ? response.data.data.rows[0].elements[0].duration.value : 0;
                        distances.push(time);
                        if (index === 0) {
                            const initialDate = new Date(selectedDate);
                            updatedTime = new Date(initialDate.getTime() + time * 1000);
                            hours.push(updatedTime);
                        } else {
                            const previousHour = new Date(hours[index - 1]);
                            updatedTime = new Date(previousHour.getTime() + time * 1000);
                            hours.push(updatedTime);
                        }
                    } else {
                        console.error("Error al obtener la distancia");
                    }
                } catch (error) {
                    console.error("Error en la llamada a la API:", error);
                }
            }
        }
        setIsLoading(false);

    };

    useEffect(async () => {
        calculateDistance(ordersInVehicleTemp);
    }, [ordersInVehicleTemp, selectedDate]);

    return (
        <>

            <p className={selectedUserVehicle.userId === undefined ? 'scheduling-screen-list-container11-title' : 'scheduling-screen-list-container11-title-selected'}>Ordenes en Vehículo ({`${props.selectedRowKeysOIV.length}/${(selectedUserVehicle.userId !== undefined ? (props.filter.length > 0 && props.activeFilter2 ? ordersInVehicleTemp.filter(item => {

                if ((item.orderNumber).toUpperCase().includes((props.filter).toUpperCase())) {
                    return true;
                }

                if ((item.address).toUpperCase().includes((props.filter).toUpperCase())) {
                    return true;
                }

                if ((item.addressComplement).toUpperCase().includes((props.filter).toUpperCase())) {
                    return true;
                }

                if ((item.custFullName).toUpperCase().includes((props.filter).toUpperCase())) {
                    return true;
                }

                if ((item.custIdentificationNumber).toUpperCase().includes((props.filter).toUpperCase())) {
                    return true;
                }

                if ((item.sector).toUpperCase().includes((props.filter).toUpperCase())) {
                    return true;
                }

                return false;
            }) : ordersInVehicleTemp) : []).length}`})</p>
            <Col span={22} className={selectedUserVehicle.userId === undefined ? 'orders-vehicle-list-screen-list-container11' : 'orders-vehicle-list-screen-list-container11-selected'}>
                {
                    (selectedUserVehicle.userId !== undefined ? (props.filter.length > 0 && props.activeFilter2 ? ordersInVehicleTemp.filter(item => {

                        if ((item.orderNumber).toUpperCase().includes((props.filter).toUpperCase())) {
                            return true;
                        }

                        if ((item.address).toUpperCase().includes((props.filter).toUpperCase())) {
                            return true;
                        }

                        if ((item.addressComplement).toUpperCase().includes((props.filter).toUpperCase())) {
                            return true;
                        }

                        if ((item.custFullName).toUpperCase().includes((props.filter).toUpperCase())) {
                            return true;
                        }

                        if ((item.custIdentificationNumber).toUpperCase().includes((props.filter).toUpperCase())) {
                            return true;
                        }

                        if ((item.sector).toUpperCase().includes((props.filter).toUpperCase())) {
                            return true;
                        }

                        return false;
                    }) : ordersInVehicleTemp) : []).map((item, index) => {
                        return <Row key={index} gutter={[0, 0]} className={`${item.orderEnableDeliveryWindow ? "orders-list-screen-list-item-row-container-time-window" : "orders-list-screen-list-item-row-container"} ${props.selectedRowKeysOIV.filter(filterItem => filterItem === item.id).length > 0 ? 'orders-list-screen-list-item-row-container-selected2' : ''}`} onClick={() => {
                            //onMouseDown={props.selectedRowKeysOIV.filter(filterItem => filterItem === item.id).length > 0 ? initialiseDrag : null} ref={props.selectedRowKeysOIV.filter(filterItem => filterItem === item.id).length > 0 ? itemList : null}
                            if (props.selectedRowKeysOIV.filter(filterItem => filterItem === item.id).length > 0) {

                                for (let i = 0; i < props.selectedRowsOIV.length; i++) {
                                    const element = props.selectedRowsOIV[i];

                                    const isEqual = (element.id === item.id);

                                    if (isEqual) {
                                        props.removeItemOIV(element);
                                    }
                                }

                            } else {
                                props.addItemOIV(item);
                            }

                        }}>
                            <Col span={24}>
                                <Row gutter={[0, 0]}>
                                    <Col span={11}>
                                        <Row className="orders-list-screen-list-item-label-1">{(item.orderNumber || 'n/a').toUpperCase()}</Row>
                                        <Row className="orders-list-screen-list-item-label-2">{item.address === null ? "" : item.address} {item.addressComplement === null ? "" : item.addressComplement}</Row>
                                        <Row className="orders-list-screen-list-item-label-3">{item.custFullName === null ? "" : item.custFullName}</Row>
                                        <Row className="orders-list-screen-list-item-label-23">{item.custPhoneNumber === null ? "" : item.custPhoneNumber}</Row>

                                    </Col>
                                    <Col span={11}>

                                        {
                                            item.consecutiveBill === null ? "" : <Row className="orders-list-screen-list-item-label-20">Factura: {item.consecutiveBill}</Row>
                                        }
                                        {
                                            item.consecutiveRemission === null ? "" : <Row className="orders-list-screen-list-item-label-21">Remisión: {item.consecutiveRemission}</Row>
                                        }
                                        {
                                            item.consecutiveSaleOrder === null ? "" : <Row className="orders-list-screen-list-item-label-22">Orden: {item.consecutiveSaleOrder}</Row>
                                        }
                                        <Row className="orders-list-screen-list-item-label-4">{item.orderWeight === null ? "" : format_number(item.orderWeight)}Kg ({item.orderTotalCubicMeters === null ? "" : format_number(item.orderTotalCubicMeters)} M3)</Row>
                                        <Row className="orders-list-screen-list-item-label-5"><div className="orders-list-screen-list-state" style={{ backgroundColor: item.color, width: '12px', height: '12px', borderRadius: '4px', marginRight: '4px', marginTop: '2px' }}></div>{item.sector === null ? "" : item.sector}</Row>
                                        <Row className="orders-list-screen-list-item-label-7">Prioridad: {item.priority === "a_high" ? "Alto" : item.priority === "b_medium" ? "Medio" : item.priority === "c_low" ? "Bajo" : ""}</Row>
                                        <Row className="orders-list-screen-list-item-label-8">{item.route === "rescheduled_delivery" ? <HistoryOutlined /> : ""}</Row>
                                    </Col>
                                    <Col span={2}>
                                        <Row className="orders-list-screen-list-item-label-6">{index + 1}</Row>
                                    </Col>
                                </Row>
                                {item.orderEnableDeliveryWindow &&
                                    <Row gutter={[0, 0]}>
                                        <Col span={24}>
                                            <Row className="orders-list-screen-list-item-label-12">Fecha de compromiso de entrega</Row>
                                            <Row gutter={[0, 0]}>
                                                <Col span={12}>
                                                    <Row className="orders-list-screen-list-item-label-10">{item.orderExpectedDeliveryStartTime === null ? "" : moment(item.orderExpectedDeliveryStartTime).format("DD/MM/YYYY hh:mm A")}</Row>
                                                </Col>
                                                <Col span={12}>
                                                    <Row className="orders-list-screen-list-item-label-10">{item.orderExpectedDeliveryEndTime === null ? "" : moment(item.orderExpectedDeliveryEndTime).format("DD/MM/YYYY hh:mm A")}</Row>
                                                </Col>
                                            </Row>

                                            <Row className="orders-list-screen-list-item-label-11">Fecha de entrega estimada</Row>
                                            <Row gutter={[0, 0]}>
                                                <Col span={20}>
                                                    <Row className="orders-list-screen-list-item-label-10">{ordersDeliveryEstimation.length > 0 && ordersDeliveryEstimation.filter(order => order.id === item.id).length > 0 ? ordersDeliveryEstimation.filter(order => order.id === item.id)[0].timeValue : ""}</Row>
                                                </Col>

                                                <Col span={4}>
                                                    <Row className="orders-list-screen-list-item-label-13">{!(ordersDeliveryEstimation.length > 0 && ordersDeliveryEstimation.filter(order => order.id === item.id).length > 0 ? moment((moment(ordersDeliveryEstimation.filter(order => order.id === item.id)[0].timeValue).format("YYYY-MM-DD HH:mm")).toString()).isBetween(moment(item.orderExpectedDeliveryStartTime).format("YYYY-MM-DD HH:mm"), moment(item.orderExpectedDeliveryEndTime).format("YYYY-MM-DD HH:mm"), 'minutes') : false) && <WarningOutlined style={{ color: 'red' }} />}</Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                                <Row gutter={[0, 0]}>
                                    <Row className="orders-list-screen-list-item-label-11">Hora entrega estimada</Row>
                                    <Col span={24}>
                                        {isLoading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <Row className="orders-list-screen-list-item-label-10" key={index}>
                                                {hours[index] ? moment(hours[index]).format("DD/MM/YYYY HH:mm:ss") : "No data"}
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    )
                }
            </Col>
            <Col span={2} className={selectedUserVehicle.userId === undefined ? 'scheduling-screen-list-button-container2' : 'scheduling-screen-list-button-container2-selected'}>

                <Button className="scheduling-screen-list-button2init" disabled={(props.filter.length > 0 && props.activeFilter2 ? ordersInVehicleTemp.filter(item => {

                    if ((item.orderNumber).toUpperCase().includes((props.filter).toUpperCase())) {
                        return true;
                    }

                    if ((item.address).toUpperCase().includes((props.filter).toUpperCase())) {
                        return true;
                    }

                    if ((item.addressComplement).toUpperCase().includes((props.filter).toUpperCase())) {
                        return true;
                    }

                    if ((item.custFullName).toUpperCase().includes((props.filter).toUpperCase())) {
                        return true;
                    }

                    if ((item.custIdentificationNumber).toUpperCase().includes((props.filter).toUpperCase())) {
                        return true;
                    }

                    if ((item.sector).toUpperCase().includes((props.filter).toUpperCase())) {
                        return true;
                    }

                    return false;
                }) : ordersInVehicleTemp).length === 0} type="primary" icon={<Icon component={MuliselectIconSVG} />} onClick={
                    () => {
                        if (!selectAll) {
                            props.addAllOIV((props.filter.length > 0 && props.activeFilter2 ? ordersInVehicleTemp.filter(item => {

                                if ((item.orderNumber).toUpperCase().includes((props.filter).toUpperCase())) {
                                    return true;
                                }

                                if ((item.address).toUpperCase().includes((props.filter).toUpperCase())) {
                                    return true;
                                }

                                if ((item.addressComplement).toUpperCase().includes((props.filter).toUpperCase())) {
                                    return true;
                                }

                                if ((item.custFullName).toUpperCase().includes((props.filter).toUpperCase())) {
                                    return true;
                                }

                                if ((item.custIdentificationNumber).toUpperCase().includes((props.filter).toUpperCase())) {
                                    return true;
                                }

                                if ((item.sector).toUpperCase().includes((props.filter).toUpperCase())) {
                                    return true;
                                }

                                return false;
                            }) : ordersInVehicleTemp));
                            setSelectAll(true);
                        } else {
                            props.clearOIV();
                            setSelectAll(false);
                        }
                    }
                } />

                <Button className="scheduling-screen-list-button2up" disabled={props.selectedRowsOIV.length !== 1 || ordersInVehicleTemp.length === 1} type="primary" icon={<CaretUpOutlined />} onClick={
                    () => {
                        const index = ordersInVehicleTemp.indexOf(props.selectedRowsOIV[0], 0);
                        if (index > 0) {
                            onSortEnd({ oldIndex: index, newIndex: index - 1 });
                        }
                    }
                } />
                <Button className="scheduling-screen-list-button2center" disabled={props.selectedRowsOIV.length !== 1 || ordersInVehicleTemp.length === 1} type="primary" icon={<CaretDownOutlined />} onClick={
                    () => {
                        const index = ordersInVehicleTemp.indexOf(props.selectedRowsOIV[0], 0);
                        if (index < ordersInVehicleTemp.length - 1) {
                            onSortEnd({ oldIndex: index, newIndex: index + 1 });
                        }
                    }
                } />
                <Button className="scheduling-screen-list-button2down" disabled={props.selectedRowsOIV.length === 0} type="primary" icon={<ArrowDownOutlined />} onClick={
                    () => {
                        onClickToRightButton();
                        props.clearOIV();
                        setSelectAll(false);
                    }
                } />
            </Col>
        </>
    );
};